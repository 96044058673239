import { Button, Link } from '@mui/material';
import React from 'react';
import { useNavigate } from "react-router-dom";

const DropdownButton = ({ menuItems }) => {

  const navigate = useNavigate();

    return (
      <div className="dropdown">
        <Button sx={{fontFamily:"CustomFont"}} className="dropbtn" variant='undefined'>{menuItems.title}</Button>
        <div className="dropdown-content">
          {menuItems.submenu.map((sub,index)=>(
            <Link key={index} component="button" variant="h6" underline="none" sx={{padding:"1rem", margin:"auto",textAlign:"center",minWidth:"100px",fontFamily:"CustomFont"}} color="black" onClick={()=>(navigate(sub.url))}>
            {sub.title}
            </Link>
          ))}
        </div>
      </div>
    );
  };

  export default DropdownButton;