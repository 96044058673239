import * as React from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import SumModal from './sumModal';
import Card from '@mui/material/Card';
import cardStyles from '../../cardStyles';

export default function BetweenModal(props) {

  const [spade, setSpade] = useState(props.spade);
  const [heart, setHeart] = useState(props.heart);
  const [diamond, setDiamond] = useState(props.diamond);
  const [club, setClub] = useState(props.club);
  const [diff4, setDiffs4] = useState(props.diff4);
  const [diff3, setDiffs3] = useState(props.diff3);
  const [diff2, setDiffs2] = useState(props.diff2);
  const [lastTime, setLastTime] = useState(props.lastTime);
  const [gameType, setGameType] = useState(props.gameType);

  useEffect(()=>{
    setSpade(props.spade);
    setHeart(props.heart);
    setDiamond(props.diamond);
    setClub(props.club);
    setLastTime(props.lastTime);
    setGameType(props.gameType);
    setDiffs4(props.diff4);
    setDiffs3(props.diff3);
    setDiffs2(props.diff2);
  },[props.diff4,props.diff3,props.diff2,props.lastTime,props.spade,props.heart,props.diamond,props.club,props.gameType])


  return (
    <>
  {gameType===4 && 
      <Box sx={{width:{xs:"90%",md:"60%"},display:"grid",gridTemplateColumns:{xs: "1fr 1fr",md:"1fr 1fr 1fr 1fr"},margin:"auto",padding:"1rem",
      textAlign:"center",columnGap:{xs:"1rem",md:"1rem"},rowGap:"1rem"}}>  
        
        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מתחת 2000</Typography>
            <SumModal sum={diff4.under2K} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 2000-5000</Typography>
            <SumModal sum={diff4.bet25K} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 5000-6000</Typography>
            <SumModal sum={diff4.bet56K} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 6000-7000</Typography>
            <SumModal sum={diff4.bet67K} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 7000-8000</Typography>
            <SumModal sum={diff4.bet78K} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>        
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 8000-9000</Typography>
            <SumModal sum={diff4.bet89K} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 9000-10000</Typography>
            <SumModal sum={diff4.bet910K} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מעל 10000</Typography>
            <SumModal sum={diff4.above10K} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        </Box>
  }

  {gameType===3 &&
    <Box sx={{width:{xs:"90%",md:"60%"},display:"grid",gridTemplateColumns:{xs: "1fr 1fr",md:"1fr 1fr 1fr 1fr"},margin:"auto",padding:"1rem",
    textAlign:"center",columnGap:{xs:"1rem",md:"1rem"},rowGap:"1rem"}}>  
      
      <Card sx={cardStyles.chance}>
      <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מתחת 500</Typography>
            <SumModal sum={diff3.under500} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 500-1000</Typography>
            <SumModal sum={diff3.bet5001K} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 1000-2000</Typography>
            <SumModal sum={diff3.bet12K} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מעל 2000</Typography>
            <SumModal sum={diff3.above2K} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>
        
      </Box>
  }

  {gameType===2 &&
    <Box sx={{width:{xs:"90%",md:"60%"},display:"grid",gridTemplateColumns:{xs: "1fr 1fr",md:"1fr 1fr 1fr 1fr"},margin:"auto",padding:"1rem",
    textAlign:"center",columnGap:{xs:"1rem",md:"1rem"},rowGap:"1rem"}}>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מתחת 50</Typography>
            <SumModal sum={diff2.under50} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 50-100</Typography>
            <SumModal sum={diff2.bet50100} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 100-200</Typography>
            <SumModal sum={diff2.bet12} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>

        <Card sx={cardStyles.chance}>
        <Box sx={{marging:"auto",display:"flex",flexDirection:"column",justifyContent:"center",rowGap:"1rem",alignItems:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מעל 200</Typography>
            <SumModal sum={diff2.above200} spade={spade} heart={heart} diamond={diamond} club={club} lastTime={lastTime}/>
        </Box>
        </Card>
        
    </Box>
  }
  </>
  );
}