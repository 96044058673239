import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "./loading.css";

function LoadingPage() {
  return (
    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
    <div className="loader-wrapper">
      <div className="line"></div>
      <div className="line"></div>
      <div className="line"></div>
    </div>
    </Box>
  );
}


// function LoadingPage() {
//   return (
//     <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
//         <CircularProgress />
//         <Typography variant="h4">Loading...</Typography>
//     </Box>
//   );
// }

export default LoadingPage;