// Centralized button styles for easy theme management
const buttonStyles = {
    common: {
      margin: "auto",
      textAlign: "center",
      fontFamily: "CustomFont",
      color: "white",
      bgcolor: "black",
    //   minWidth: "150px",
      maxWidth: {xs:"80%",md:"150px"},
      width: "80%",
      padding: "10px 20px",
      "&:hover": {
        bgcolor: "green",
        color: "white",
    }
    },
    rotation: {
        color: "white",
        bgcolor: "black",
        fontFamily: "CustomFont",
        minWidth: "150px", 
        "&:hover": {
            bgcolor: "green",
            color: "white",
        }
    },
    random: {
        color: "white",
        bgcolor: "black",
        fontFamily: "CustomFont",
        "&:hover": {
            bgcolor: "green",
            color: "white",
        }
    },
    search: {
        margin: "auto",
        marginBottom: "1rem",
        textAlign: "center",
        fontFamily: "CustomFont",
        color: "white",
        bgcolor: "black",
        minWidth: "100px",
        "&:hover": {
            bgcolor: "green",
            color: "white",
        }
    },
    sumModal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "70%",
        minHeight: "60px",
        minWidth: "60px",
        fontSize: "1rem",
        color: "white",
        bgcolor: "green",
        fontWeight: "bold",
        "&:hover": {
            bgcolor: "green",
            color: "white",
        }
    },
    splitModal: {
        margin: "auto",
        textAlign: "center",
        fontFamily: "CustomFont",
        color: "white",
        bgcolor: "black",
        width: { xs: "6rem", md: "8rem" },
        // minWidth: "100px",
        // maxWidth: "130px",
        // width: { xs: "10rem", md: "100%" },
        padding: "8px",
        "&:hover": {    
            bgcolor: "green",
            color: "white",
        }
    },
    topButton: {
        maxWidth: "150px",
        minWidth: "100px",
        padding: "10px",
        fontWeight: 400,
        fontSize: "larger",
        fontFamily: 'CustomFont',
        cursor: "pointer",
        borderRadius: "6px",
        border: (props) => props.type === "filled" && "none",
        backgroundColor: (props) => props.type === "filled" ? "black" : "transparent",
        color: (props) => props.type === "filled" && "white",
        "&:hover": {
            backgroundColor: "green",
            color: "white",
        }
    },
    button: {
        width: "100%",
        padding: "10px",
        backgroundColor: "black",
        color: "white",
        fontWeight: 600,
        cursor: "pointer",
        marginBottom: "1rem",
        "&:hover": {
            backgroundColor: "green",
            color: "white",
        }
    },
    sumModalPopUp:{
        // margin: "auto",
        textAlign: "center",
        fontFamily: "CustomFont",
        color: "white",
        bgcolor: "black",
        width: { xs: "6rem", md: "100px" },
        maxHeight: { xs: "3rem", md: "3rem" },
        maxWidth: "100px",
        padding: "8px",
        "&:hover": {
            bgcolor: "green",
            color: "white",
        }
    },
    loginMobile: {
        textAlign: "center",
        fontFamily: "CustomFont",
        color: "white",
        bgcolor: "black",
        padding: "8px",
        width: "100%",
        maxWidth: "6rem",
        maxHeight: "3rem",
        border:"1px solid black",
        "&:hover": {
            bgcolor: "green",
            color: "white",
        },
        display: {xs: "flex", md: "none"}
    },
    loginDesktop: {
        color: "white",
        bgcolor: "black",
        fontFamily: "CustomFont",
        // width: "100px",
        width: "80%",
        maxWidth: {xs:"80%",md:"150px"},
        maxHeight: "3rem",
        padding: "10px 20px",
        "&:hover": {
            bgcolor: "green",
            color: "white",
        },
        display: {xs: "none", md: "flex"}
    },
    sumModalClose: {
        color: "red",
        borderColor: "red",
        fontFamily: "CustomFont",
        minWidth: "30px",
        padding: "4px",
        "&:hover": {
            bgcolor: "rgba(255, 0, 0, 0.1)",
            borderColor: "red",
        }
    },
    notFound: {
        color: "white",
        bgcolor: "black",
        fontFamily: "CustomFont",
        minWidth: "150px", 
        "&:hover": {
            bgcolor: "green",
            color: "white",
        }
    },
  };
  
export default buttonStyles;

// sx={{fontFamily:"CustomFont",color:"white",backgroundColor:"black",display: {xs: "none", md: "flex"}}}>{text ? text: "הכנס/הרשם"}</Button>
// sx={{fontFamily:"CustomFont",color:"white",backgroundColor:"black",display: {xs: "flex", md: "none"}}}>{text ? text: "הכנס"}</Button>