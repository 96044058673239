import React, { Fragment, useEffect, useState } from 'react'
import Checkbox from '@mui/material/Checkbox';
import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import useIsMobile from '../../constants/useIsMobile';

function DiamondBox({setDiamond,cleared,diamond,setValue}) {
  const faceCard = ["7","8","9","10","J","Q","K","A"]

  const diamondTemplate = {
    H7: false,
    H8: false,
    H9: false,
    H10: false,
    HJ: false,
    HQ: false,
    HK: false,
    HA: false
  };

  const [diamonds, setDiamonds] = useState(diamondTemplate);
  const isMobile = useIsMobile();

  useEffect(()=>{
    if(diamond !== ""){
      setDiamonds({ ...diamondTemplate, [`H${diamond}`]: true });
    }
  },[diamond])

  useEffect(()=>{
    if(cleared == true){
      setDiamonds(diamondTemplate);
    }
  },[cleared])

  const handleDiamondChange = (event) => {
    if(event.target.checked === false){
      setDiamond("");
    }else{
      setDiamond([event.target.value]);
    }
    setDiamonds({ ...diamondTemplate, [event.target.name]: event.target.checked });
    // setValue(3)
  };

  return (
    <Fragment>
      {/* Mobile */}
      {isMobile && (
      <Box sx={{display: {xs:"block",md:"none"}}}>
        <Box sx={{margin:"auto",width:"100%",height:"auto",display:"grid",gridAutoFlow:"row",marginBottom:"1rem"}}>
        <Box sx={{textAlign:"center",margin:"auto",width:"100%",height:"auto",display:"grid",gridAutoFlow:"column",bgcolor:""}}>
          {faceCard.map((face,index)=>
          <FormControlLabel key={index} sx={{margin:"auto",width:"fit-content"}}
          control={
            <Box sx={{bgcolor:"white",width:"auto",margin:"auto",display:"grid",gridAutoFlow:"row",border:"1px black solid",borderRadius:"8px"}}>
              <Typography variant='h6' textAlign={"center"}>{face}</Typography> 
              <Checkbox size='small' key={index} checked={diamonds[`H${face}`]} onChange={handleDiamondChange} name={`H${face}`} value={face}/>
            </Box>
          }
          />
          )}
        </Box>
        </Box>
      </Box>
      )}
    
      {/* Desktop */}
      {!isMobile && (
      <Box sx={{display: {xs:"none",md:"block"}}}>
      <Box sx={{margin:"auto",width:"100%",display:"grid",gridAutoFlow:"column",padding:"6px"}}>
      {faceCard.map((face,index)=>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        key={index}
        >
          <Grid item xs={"auto"}>
            <FormControlLabel
            control={
              <Box sx={{bgcolor:"white",width:"auto",margin:"auto",display:"grid",gridAutoFlow:"row",border:"1px black solid",borderRadius:"8px"}}>
                <Typography variant='h6' textAlign={"center"}>{face}</Typography> 
                <Checkbox key={index} checked={diamonds[`H${face}`]} onChange={handleDiamondChange} name={`H${face}`} value={face}/>
              </Box>
            }
            />
          </Grid>
      </Grid>,
      )}
      </Box>
      </Box>
      )}
      
    </Fragment>
  )
}

export default DiamondBox;