import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Menu, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import SpadeBox from './spadebox';
import HeartBox from './heartbox';
import DiamondBox from './diamondbox';
import ClubBox from './clubbox';
import SearchFunction from '../../constants/search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  display:"flex",
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "8px" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function SearchAppBar() {

  // Menu & Tabs
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Ends Here

  const [spade, setSpade] = useState("")
  const [heart, setHeart] = useState("")
  const [diamond, setDiamond] = useState("")
  const [club, setClub] = useState("")
  const [cleared, setCleared] = useState(false)

  function Clear(){
    setCleared(true)
    setSpade("");
    setHeart("");
    setDiamond("");
    setClub("");
  }

  const searchValue =
    spade || heart || diamond || club
      ? `${spade ? spade : "X"} ${heart ? heart : "X"} ${diamond ? diamond : "X"} ${club ? club : "X"}`
      : "חפש";

  return (
    <>
    <Search sx={{direction:"ltr"}}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="חפש"
        inputProps={{ 'aria-label': 'search' }}
        readOnly={true}
        // onChange={(e)=>(console.log(e.target.value))}
        onClick={handleOpenMenu}
        value={searchValue}

      />
    </Search>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      // anchorPosition={{ top: "50%", left: "50%" }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{maxHeight:"80%",maxWidth:"100%"}}>
      <Box sx={{display:"flex",flexDirection:"column",width:"100%",overflow: 'auto'}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="Card suits" centered>
            <Tab label="&#9824;" aria-label="spade" sx={{fontSize:'3rem',color:"black"}} {...a11yProps(0)} />
            <Tab label="&#9829;" aria-label="heart" sx={{fontSize:'3rem',color:"red"}} {...a11yProps(1)} />
            <Tab label="&#9830;" aria-label="diamond" sx={{fontSize:'3rem',color:"black"}} {...a11yProps(2)} />
            <Tab label="&#9827;" aria-label="club" sx={{fontSize:'3rem',color:"red"}} {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SpadeBox setSpade={setSpade} spade={spade} cleared={cleared} setValue={setValue}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <HeartBox setHeart={setHeart} heart={heart} cleared={cleared} setValue={setValue}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DiamondBox setDiamond={setDiamond} diamond={diamond} cleared={cleared} setValue={setValue}/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ClubBox setClub={setClub} club={club} cleared={cleared} setValue={setValue}/>
        </TabPanel>
      </Box>
      <SearchFunction spade={spade} heart={heart} diamond={diamond} club={club} cleared={cleared} setCleared={setCleared} funcClear={Clear}/>
    </Menu>
  </>
  );
}