import * as React from 'react';
import { useState,useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Drawer from '@mui/material/Drawer';
import { Badge } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import * as ROUTES from '../../constants/routes';
import SearchAppBar from './search';
import { ProfileContext, UserContext, CartContext } from '../../App';
import LoginModal from './loginModal';
import { googleLogout } from '@react-oauth/google';
import axios from  'axios';
import { useDispatch,useSelector } from 'react-redux';
import { selectResultHistory, setHistory} from '../../slices/resultHistory';
import DropdownButton from './Dropdown.jsx';
import MobileMenu from './MobileMenu.jsx';
import SystemMessages from './systemmessages';
import axiosInstance from '../../constants/axiosInstance';
// import useSWR from 'swr';

const settings = ['חשבון שלי', 'התנתק'];

const adminMenu = [
  {
    title: 'Admin',
    url: ROUTES.ADMIN,
  },
  {
    title: 'DashBoard',
    url: ROUTES.STATIONHOME,
  }
];

const menuItems = [
    {
      title: 'בית',
      url: ROUTES.HOME,
    },
    {
      title: 'צאנס',
      submenu: [
        {
          title: 'צאנס 1',
          url: ROUTES.CHANCE1,
        },
        {
          title: 'צאנס 2',
          url: ROUTES.CHANCE2,
        },
        {
          title: 'צאנס 3',
          url: ROUTES.CHANCE3,
        },
        {
          title: 'צאנס 4',
          url: ROUTES.CHANCE4,
        },
      ],
    },
    {
      title: 'ממוצע',
      submenu: [
        {
            title: 'צאנס 2',
          url: ROUTES.CHANCE2DIF,
        },
        {
            title: 'צאנס 3',
          url: ROUTES.CHANCE3DIF,
        },
        {
            title: 'צאנס 4',
          url: ROUTES.CHANCE4DIF,
        },
      ],
    },
    {
      title: 'זוגות',
      url: ROUTES.SPLITS,
    },
    {
      title: 'שלח טופס',
      url: ROUTES.SEND,
    },
    {
      title: 'קבוצות',
      url: ROUTES.GROUPS,
    }
];

// const fetcher = url => axios.get(url).then(res => res.data)

const ResponsiveAppBar = () => {

    const navigate = useNavigate();
    const {userContext, setUserContext} = useContext(UserContext);
    const {profileContext, setProfileContext} = useContext(ProfileContext);
    const {cartContext, setCartContext} = useContext(CartContext);
    // const { data, error } = useSWR(`${process.env.REACT_APP_API_URL}/results/`, fetch);
    const dispatch = useDispatch();
    const resultHistory = useSelector(selectResultHistory);
    const id = userContext?.id;
    const [cartCount, setCartCount] = useState(cartContext?.length || 0);
    const [requestMade, setRequestMade] = useState(false);
    const [messages, setMessages] = useState([]);
    // const [openSubmenu, setOpenSubmenu] = useState(null);

    // using SWR for data fetching
    // const { data, error, isLoading } = useSWR(`${process.env.REACT_APP_API_URL}/results/`, fetcher, { refreshInterval: 0 })
    // useEffect(()=>{
    //   console.log(data);
    //   if(isLoading){
    //     console.log("Loading...");;
    //   }
    //   if(data !== undefined){
    //     dispatch(setHistory(data.data[0]));
    //   }
    
    //   if(error){
    //     console.log("Error");
    //     console.log(error.message);
    //   }
    // },[data,error,isLoading])

    useEffect(()=>{
        var userActive = JSON.parse(localStorage.getItem('activeUser'));
        if(userActive){
          setUserContext(userActive)
        }else{
          setUserContext(null)
        }
    },[])

    useEffect(()=>{
        if(userContext?.id && !cartContext.length && !requestMade){
          axiosInstance.get(`${process.env.REACT_APP_API_URL}/profile/${id}`)
          .then((response)=>{
            if(Array.isArray(response.data.data.profile.cart)){
              // console.log(response.data.data.profile.cart);
              setCartContext(response.data?.data?.profile?.cart)
            }else{
              setCartContext([])
            }
            setRequestMade(true);
          }).catch((error)=>{
              console.log(error);
          })
        }
      },[userContext,cartContext,requestMade])    

    useEffect(() => {
        let cancel;
        if (resultHistory?.Draw.length === 0 || resultHistory === null) {
          const source = axios.CancelToken.source();
          axios
            .get(`${process.env.REACT_APP_API_URL}/results/`, {
              cancelToken: source.token,
            })
            .then((response) => {
              let data = response.data.data[0];
              dispatch(setHistory(data));
              // if (data['Draw']) {
              //   for (let i = 0; i < 100; i++) {
              //   }
              // }
            })
            .catch((error) => {
              if (!axios.isCancel(error)) {
                console.log(error);
              }
            });
          cancel = () => source.cancel();
        }
        return () => {
          if (cancel) {
            cancel();
          }
        };
      }, [resultHistory, dispatch]);

    useEffect(()=>{
        setCartCount(cartContext?.length)
    },[cartContext])

    function logout(){
        setUserContext(null);
        setProfileContext(null);
        setCartContext([]);
        setRequestMade(false);
        localStorage.clear();
        googleLogout();
    }

    // Profile Icon Menu list
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (e) => {
        if(e === "חשבון שלי"){
            navigate(ROUTES.PROFILE)
        }
        if(e === "התנתק"){
            logout()
        }
        setAnchorElUser(null);
    };    

    // Mobile Menu Drawer
    const [state, setState] = useState(false);

    const toggleDrawer = (open) => {
      setState(open);
    };

    useEffect(()=>{
      axiosInstance.get(`${process.env.REACT_APP_API_URL}/metadata/`)
      .then((response)=>{
          // console.log(response.data.data);
          setMessages(response.data.data)
      }).catch((error)=>{
          console.log(error);
      })
  },[])

    
    return (
        <>
        {/* Navbar Background Color */}
        <AppBar position="static" sx={{bgcolor:"black",direction:"rtl"}}>
        <Container maxWidth="xl">
            <Toolbar disableGutters>
            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
                variant="h6"
                noWrap
                component="a"
                onClick={() => navigate('/')}
                sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                cursor: "pointer"
                }}
            >
                LOGO
            </Typography>

            {/* Side Menu ICON Section - On Mobile View */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                size="large"
                aria-label="open navigation bar"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // onClick={toggleDrawer(true)}
                onClick={()=>(toggleDrawer(true))}
                color="inherit"
                >
                <MenuIcon />
                </IconButton>
            </Box>


            {/* Logo Section - Hidden for now on both views*/}
            <AdbIcon sx={{ display: { xs: 'none', md: 'none' }, mr: 1 }} />
            <Typography
                variant="h5"
                noWrap
                component="a"
                onClick={() => navigate('/')}
                sx={{
                mr: 2,
                display: { xs: 'none', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
                LOGO
            </Typography>


            {/* Menu list Section - Desktop View  */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {menuItems.map((item) => (
                item.submenu ? (
                <DropdownButton key={item.title} menuItems={item} />
                ) : (
                <Button sx={{fontFamily:"CustomFont"}} variant='undeifned' key={item.title} onClick={()=>(navigate(item.url))}>{item.title}</Button>
                )
            ))}
            {userContext?.isAdmin &&
            <Button sx={{fontFamily:"CustomFont",color:"white"}} variant='undeifned' key={adminMenu[0].title} onClick={()=>(navigate(adminMenu[0].url))}>{adminMenu[0].title}</Button>
            }
            {userContext?.stationOwner?.owner &&
            <Button sx={{fontFamily:"CustomFont",color:"white"}} variant='undeifned' key={adminMenu[1].title} onClick={()=>(navigate(adminMenu[1].url))}>{adminMenu[1].title}</Button>
            }
            </Box>


            {/* Search Bar Section */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                <SearchAppBar />
            </Box>

            
            {/* Cart Icon */}
            {userContext?.name &&
            <Box sx={{ flexGrow: 0 }}>
            <IconButton
                size="large"
                aria-label="go to cart page"
                aria-controls="go-to-cart"
                aria-haspopup="false"
                onClick={()=>{navigate(ROUTES.CART)}}
                color="inherit"
                >
                <Badge badgeContent={cartCount} color='primary'>
                {/* <Badge badgeContent={cartContext?.length} color='primary'> */}
                    <ShoppingCartIcon />
                </Badge>
                </IconButton>
            </Box>
            }


            {/* User Profile Section */}
            {userContext?.name &&
            <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={userContext.name} src="#" />
                </IconButton>
                </Tooltip>
                <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                >
                {settings.map((setting) => (
                    <MenuItem key={setting} onClick={(e)=>{
                        handleCloseUserMenu(e.target.innerText);
                        }}>
                    <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                ))}
                </Menu>
            </Box>
            }

            {!userContext?.name && 
                <LoginModal />
            }

            </Toolbar>
        </Container>
        </AppBar>

        <Drawer
            anchor={'right'}
            open={state}
            onClose={()=>(toggleDrawer(false))}
            sx={{display: {xs:"block",md:"none"}}}
        >
          <MobileMenu toggleDrawer={toggleDrawer} />
            {/* {list()} */}
        </Drawer>

        {messages?.length>0 &&
          messages.map((item,index)=>(
            <SystemMessages key={index} message={item}/>
          ))
        }
        </>
    );
};
export default ResponsiveAppBar;