import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
// import { useSelector } from 'react-redux';
// import { selectResultHistory } from '../../slices/resultHistory';
import { Box } from '@mui/system';
import { addText } from '../../constants/printTickets';
import axios from '../../constants/axiosInstance'
import { CartContext, UserContext } from '../../App';
import { toast } from 'react-toastify';
import LoginModal from '../navBar/loginModal';
import buttonStyles from '../../buttonStyles';

function AddCartButton(props) {

  const {userContext, setUserContext} = useContext(UserContext);
  const {cartContext, setCartContext} = useContext(CartContext);
  const ownerid = userContext?.id;
  
  // const resultHistory = useSelector(selectResultHistory)
  const [spade, setSpade] = useState(props.spade);
  const [heart, setHeart] = useState(props.heart);
  const [diamond, setDiamond] = useState(props.diamond);
  const [club, setClub] = useState(props.club);
  const [error, setError] = useState(props.error);
  const [selectedCards, setSelectedCards] = useState([spade, heart, diamond, club]);
  const [gameName, setGameName] = useState("");
  const [allOR4, setAllOR4] = useState("Chance4");

  const isInvalid = spade === '' && heart === '' && diamond === '' && club === '';
  const isInvalid2 = spade?.length>4 || heart?.length>4 || diamond?.length>4 || club?.length>4;
  const isInvalid3 = gameName === 'Chance4' && allOR4 === '';

  // useEffect(() => {
  //   console.log(gameName);
  // }, [gameName]);

  useEffect(() => {
    if(gameName === ""){
      setError(true);
    }
  },[selectedCards]);

  function checkGameName(){
    let counter=0;
    if(spade?.length > 0){
      counter++
    }
    if(heart?.length > 0){
      counter++
    }
    if(diamond?.length > 0){
      counter++
    }
    if(club?.length > 0){
      counter++
    }

    // Determine game type based on checked boxes
    if (counter === 1) {
      setGameName("Chance1");
    } else if (counter === 2) {
      setGameName("Chance2");
    } else if (counter === 3) {
      setGameName("Chance3");
    } else if (counter === 4) {
      setGameName("Chance4");
    } else {
      setGameName(""); // No valid game found
      // setError(true);
    }
  }

  useEffect(() => {
    setSelectedCards([[spade, heart, diamond, club]]);
    checkGameName();
  }, [spade, heart, diamond, club]);

  useEffect(()=>{
    setSpade(props.spade)
    setHeart(props.heart)
    setDiamond(props.diamond)
    setClub(props.club)
    setError(props.error)
  },[props.spade,props.heart,props.diamond,props.club,props.error])

  // PDF in new window
  // function addToCart(){
  //   // It takes time to update states, double check before sending to cart.
  //   if(!error && spade.length<=4 && heart.length<=4 && diamond.length<=4 && club.length<=4){
  //     console.log(selectedCards);
  //     addText(selectedCards,gameName);
  //   }
  // }

  function addToCart(){
    // It takes time to update states, double check before sending to cart.
    if(!error && spade.length<=4 && heart.length<=4 && diamond.length<=4 && club.length<=4 && userContext){
      // console.log(selectedCards);
      let gamename=gameName;
      if(gameName === "Chance4" && allOR4==="ChanceAll"){
        gamename = "ChanceAll";
      }else if(gameName === "Chance4" && allOR4==="Chance4"){
        gamename = "Chance4";
      }
      // console.log(gamename);
      // addText(selectedCards,gameName);

      axios.post(`${process.env.REACT_APP_API_URL}/profile/cart`,{
        ownerid,
        gamename,
        selectedCards
      }).then((response)=>{
        // console.log(response);
        setCartContext(response.data?.cart);
        // let message = <span>&#x2660;{spade} {heart} {diamond} {club} Added to Cart</span>;
        let message = "Added to Cart";
        let suits = [];
        if (spade.length>0) {
          suits.push(<span key="spade" style={{marginRight: "10px"}}>&#x2660;{spade} </span>);
        }
        if (heart.length>0) {
          suits.push(<span key="heart" style={{marginRight: "10px"}}>&#x2665;{heart} </span>);
        }
        if (diamond.length>0) {
          suits.push(<span key="diamond" style={{marginRight: "10px"}}>&#x2666;{diamond} </span>);
        }
        if (club.length>0) {
          suits.push(<span key="club" style={{marginRight: "10px"}}>&#x2663;{club} </span>);
        }
        if (suits.length > 0) {
          message = <span>{suits} {message}</span>;
        } else {
          message = `${message}`;
        }
        // toast.success('Added to Cart', {
        // toast.success(`&#x2660 ${spade} ${heart} ${diamond} ${club} Added to Cart`, {
        toast.success(message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

      }).catch((error)=>{
          setError(error.message)
          // toast.error('There was an error!', {
          toast.error(`${error.response.data}`, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
      })
    }else if(!userContext || userContext===null){
      console.log("Open Login Window");
    }
  }
  const handleChange = (event) => {
    // setGameName(event.target.value);
    setAllOR4(event.target.value);
  };

  // const buttonStyle = {
  //   margin: "auto",
  //   textAlign: "center",
  //   fontFamily: "CustomFont",
  //   minWidth: "100px",
  //   maxWidth: "150px",
  //   width: "80%",
  //   padding: "10px 20px",
  //   color:"white",
  //   backgroundColor:"green"
  // };
  const boxStyle = {
    textAlign: "center",
    margin: "auto",
    padding: { xs: "1rem", md: "1rem" },
    width: { xs: "90%", md: "60%" },
    display: "grid",
    gridAutoFlow: { xs: "row", md: "column" },
    columnGap: "1rem",
    rowGap: "1rem"
  };

  return (
      <Box sx={boxStyle}>
      { (gameName==="Chance4" || gameName==="ChanceAll") && 
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Fragment>
            <Typography>סוג משחק:</Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="select-price-radio-buttons-group-label"
                name="select-price-radio-buttons-group"
                // value={gameName}
                value={allOR4}
                onChange={handleChange}
              >
                <FormControlLabel value={"Chance4"} control={<Radio />} label="צאנס 4" />
                <FormControlLabel value={"ChanceAll"} control={<Radio />} label="רב צאנס" />
              </RadioGroup>
            </FormControl>
            </Fragment>
        </Box>
      }

      {userContext ? <Button disabled={error || isInvalid || isInvalid2 || isInvalid3} sx={buttonStyles.common} variant="contained" onClick={()=>{
        addToCart();
      }}>שלח לסל</Button>
      :
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <LoginModal text={"שלח לסל"}/>
      </Box>}

      {/* <Button disabled={error || isInvalid || isInvalid2 || isInvalid3} sx={{margin:"auto",textAlign:"center",fontFamily:"CustomFont"}} variant="contained" onClick={()=>{
        addToCart();
      }}>שלח לסל</Button> */}

      <Button disabled={isInvalid} sx={buttonStyles.common} variant="contained" onClick={()=>{
        props.funcClear();
      }}>נקה</Button>
      </Box>
  )
}

export default AddCartButton;