import { createSlice } from '@reduxjs/toolkit'

const initialState ={
    draw: null,
    spade: null,
    heart: null,
    diamond: null,
    club: null,
}

export const resultSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    setDraw: (state,action)=>{
        state.draw = action.payload;
        console.log(action.payload);
    },
    setSpade: (state,action)=>{
        state.spade = action.payload;
    },
    setHeart: (state,action)=>{
        state.heart = action.payload;
    },
    setDiamond: (state,action)=>{
        state.diamond = action.payload;
    },
    setClub: (state,action)=>{
        state.club = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setDraw, setSpade,setHeart,setDiamond,setClub } = resultSlice.actions

// Selectors
export const selectDraw = (state) => state.results.draw;
export const selectSpade = (state) => state.results.spade;
export const selectHeart = (state) => state.results.heart;
export const selectDiamond = (state) => state.results.diamond;
export const selectClub = (state) => state.results.club;

export default resultSlice.reducer