import React, { Component } from 'react';
import axios from '../constants/axiosInstance'


// make global function to send errors
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
    // Send error to server and create log file
    // axios.post(`${process.env.REACT_APP_API_URL}/error-logs`, {
    //   error: error.toString(),
    //   info: info.componentStack,
    // })
    //   .then(response => {
    //     // Log created successfully
    //     console.log(response.data);
    //   })
    //   .catch(error => {
    //     // Error occurred while creating log
    //     console.error(error);
    //   });
  }

  render() {
    if (this.state.hasError) {
      // fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;