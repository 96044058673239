import React, { Fragment, useContext, useEffect, useState } from 'react'
import useIsMobile from '../../constants/useIsMobile';
import { Box, Button, Checkbox, Collapse, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { toast } from 'react-toastify';
import axios from '../../constants/axiosInstance'
import { CartContext, UserContext } from '../../App';
import LoginModal from '../navBar/loginModal';
import buttonStyles from '../../buttonStyles';

// Choose pair of cards
// choose to filly cover remaining cards (1x1x8x8) or without the pair (1x1x7x7)
// choose in what game to send, Chance4 or 3

function SendPair(props) {

    const {userContext, setUserContext} = useContext(UserContext);
    const {cartContext, setCartContext} = useContext(CartContext);
    const ownerid = userContext?.id;

    const [sendButton, setSendButton] = useState(props.sendButton);
    const [pairface, setPairface] = useState(props.pairface);

    useEffect(()=>{
      setPairface(props.pairface);
      setSendButton(props.sendButton);
    },[props.pairface, props.sendButton]);

    const faceCard = ["7","8","9","10","J","Q","K","A"]
    const cardTemplate = {
        H7: false,
        H8: false,
        H9: false,
        H10: false,
        HJ: false,
        HQ: false,
        HK: false,
        HA: false
    };
    const setTemplate = [
        [true,true,true,false],
        [true,true,false,true],
        [true,false,true,true],
        [false,true,true,true],
    ];

    // Change the initial state to store only the selected card
    const [cards, setCards] = useState("");
    // const [filteredCards, setFilteredCards] = useState([]);
    const [selectedCards, setSelectedCards] = useState([]);
    const [gameName, setGameName] = useState("");
    const [gameNameHebrew, setGameNameHebrew] = useState("");
    const [allOR4, setAllOR4] = useState("Chance4");
    const isMobile = useIsMobile();

    // function checkGameName(){
    //     // Determine game type based on filteredCards length
    //     if (filteredCards.length >= 4) {
    //         setGameName("Chance4")
    //         setGameNameHebrew("צאנס 4")
    //     } else if (filteredCards.length === 3) {
    //         setGameName("Chance3")
    //         setGameNameHebrew("צאנס 3")
    //     } else if (filteredCards.length === 2) {
    //         setGameName("Chance2")
    //         setGameNameHebrew("צאנס 2")
    //     } else if (filteredCards.length === 1) {
    //         setGameName("Chance1")
    //         setGameNameHebrew("צאנס 1")
    //     } else {
    //         setGameName("")
    //         setGameNameHebrew("")
    //     }
    // }

    // useEffect(() => {
    //     setFilteredCards(Object.keys(cards).filter(card => cards[card]).map(card => card.substring(1)));
    // },[cards]);

    const hasError = Object.values(cards).filter(Boolean).length < 1;
    const errorEmpty = Object.values(cards).filter(Boolean).length === 0;

    // Define generatePermutationsBelow outside of generatePermutationsAbove
    function generatePermutationsBelow(numbers) {
        console.log(numbers);
        const permutations = [];

        function backtrack(temp, used) {
            if (temp.length === numbers.length) {
                permutations.push(temp.slice());
                return;
            }

            for (let i = 0; i < numbers.length; i++) {
                if (used[i]) continue;
                if (i > 0 && numbers[i] === numbers[i - 1] && !used[i - 1]) continue;
                used[i] = true;
                temp.push(numbers[i]);
                backtrack(temp, used);
                used[i] = false;
                temp.pop();
            }
        }

        backtrack([], []);
        console.log(permutations);
        return permutations;
    }

    function generatePermutationsAbove(numbers) {
        console.log(numbers);
        const permutations = [];

        function backtrack(temp, used) {
            if (temp.length === 4) { // Ensure only permutations of length 4 are considered
                permutations.push(temp.slice());
                return;
            }

            for (let i = 0; i < numbers.length; i++) {
                if (used[i]) continue; // Skip used cards
                used[i] = true;
                temp.push(numbers[i]);
                backtrack(temp, used);
                used[i] = false;
                temp.pop();
            }
        }

        backtrack([], Array(numbers.length).fill(false)); // Initialize 'used' array
        console.log(permutations);
        return permutations;
    }
      

    function generateTickets(pairValue) {
        // console.log(pairValue);
        let permutations = [];
        const values = ["7", "8", "9", "10", "J", "Q", "K", "A"]; // All possible values including the pair value
        if (pairValue === "") {
            return [];
        }
        // Generate all rotations for the pair
        for (let i = 0; i < 4; i++) { // Rotate pair through all positions
            for (let j = i + 1; j < 4; j++) {
                values.forEach(firstRemaining => {
                    values.forEach(secondRemaining => {
                        let ticket = [null, null, null, null];
                        ticket[i] = pairValue;
                        ticket[j] = pairValue;
                        let k = 0;
                        for (let m = 0; m < 4; m++) {
                            if (ticket[m] === null) {
                                if (k === 0) {
                                    ticket[m] = firstRemaining;
                                    k++;
                                } else if (k === 1) {
                                    ticket[m] = secondRemaining;
                                    k++;
                                }
                            }
                        }
                        permutations.push(ticket);
                    });
                });
            }
        }

        const tickets = permutations.map(permutation => permutation.map(value => [value]));
        // console.log(tickets);
        return tickets;
    }

    // function generateTickets(pairValue) {
    //     console.log(pairValue);
    //     let permutations = [];
    //     const remainingValues = ["7", "8", "9", "10", "J", "Q", "K", "A"].filter(value => value !== pairValue);

    //     // Generate all rotations for the pair
    //     for (let i = 0; i < 4; i++) { // Rotate pair through all positions
    //         for (let j = i + 1; j < 4; j++) {
    //             remainingValues.forEach(firstRemaining => {
    //                 remainingValues.forEach(secondRemaining => {
    //                     let ticket = [null, null, null, null];
    //                     ticket[i] = pairValue;
    //                     ticket[j] = pairValue;
    //                     let k = 0;
    //                     for (let m = 0; m < 4; m++) {
    //                         if (ticket[m] === null) {
    //                             if (k === 0) {
    //                                 ticket[m] = firstRemaining;
    //                                 k++;
    //                             } else if (k === 1) {
    //                                 ticket[m] = secondRemaining;
    //                                 k++;
    //                             }
    //                         }
    //                     }
    //                     permutations.push(ticket);
    //                 });
    //             });
    //         }
    //     }

    //     const tickets = permutations.map(permutation => permutation.map(value => [value]));
    //     console.log(tickets);
    //     return tickets;
    // }

    useEffect(() => {
        // console.log(cards);
        setSelectedCards(generateTickets(cards));
        // checkGameName();
    }, [cards]);

    // useEffect(() => {
    //     console.log(selectedCards);
    // }, [selectedCards]);

    // useEffect(() => {
    //     console.log(gameName);
    // }, [gameName]);

    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

    const handleDescriptionClick = () => {
      setIsDescriptionVisible(!isDescriptionVisible);
    };
  
    // Modify the handleChange function to update the state with the selected card
    const handleChange = (event) => {
        setCards(event.target.value);
    };

    // const handleChange = (event) => {
    //     // Update the state with the selected card,and reset the rest to cardTemplate/false
    //     setCards({
    //         ...cards,
    //         [event.target.name]: event.target.checked,
    //     });
    // }

    const handleRadioChange = (event) => {
        setAllOR4(event.target.value);
    };

    function addToCart(){
        
        // It takes time to update states, double check before sending to cart.
        if((!hasError && userContext) || sendButton){
        //   console.log(selectedCards);
          let gamename=gameName;
          if(gameName === "Chance4" && allOR4==="ChanceAll"){
            gamename = "ChanceAll";
          }else if(gameName === "Chance4" && allOR4==="Chance4"){
            gamename = "Chance4";
          }
          // addText(selectedCards,gameName);
    
          axios.post(`${process.env.REACT_APP_API_URL}/profile/cartmany`,{
            ownerid,
            gamename,
            selectedCards
          }).then((response)=>{
            // console.log(response);
            setCartContext(response.data?.cart);
            toast.success("נשלח לסל בהצלחה", {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
    
          }).catch((error)=>{
            //   setError(error.message)
              // toast.error('There was an error!', {
              toast.error(`${error.response.data}`, {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
          })
        }else if(!userContext || userContext===null){
          console.log("Open Login Window");
        }
      }

    // Ensure that useEffect is used to update selectedCards whenever pairface changes
    useEffect(() => {
        if (sendButton) {
            const tickets = generateTickets(pairface);
            setSelectedCards(tickets);
        }
    }, [pairface, sendButton]); // Add sendButton to dependency array if its changes should also trigger the update

    if (sendButton === true) {
        return (
            <Button sx={buttonStyles.splitModal} onClick={() => addToCart()}>שלח לסל</Button>
        );
    }
    else{
      return (
      <Box sx={{ margin: 'auto', padding: {xs: "0", md: "1rem"} }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
        variant="text"
        startIcon={isDescriptionVisible ? <ExpandLess /> : <ExpandMore />}
        onClick={handleDescriptionClick}
        sx={{
            color: 'black',
            fontFamily: 'CustomFont',
            textDecoration: 'underline',
            fontSize: '1rem'
        }}
        size='large'
        >
            ?זוג מסתובב
        </Button>
        </Box>
        <Collapse in={isDescriptionVisible}>
          <Box sx={{ padding: {xs: "0", md: "1rem"}, fontFamily: 'CustomFont', textAlign: 'right',display:"flex", flexDirection:"column",rowGap:"8px" }}>
            <Fragment>
            <ul style={{ direction: 'rtl', textAlign: 'right' }}>
              <li>בחר 1 מ 8 קלפים</li>
              <li>המערכת נותנת כל הצירופים האפשרים עם הזוג הנבחר לא משנה המיקום. </li>
              <li>אם עלה בהגרלה הזוג שבחרת אז זכית בפרס ראשון, לא משנה הסדר / מיקום</li>
              <li>שים לב, אם עלה הקלף שבחרת בזוג 3 פעמים אז תזכה ב 3 פרס ראשון.</li>
              <li>אם עלה הקלף שבחרת בזוג 4 פעמים אז תזכה ב 6 פרס ראשון.</li>
              <li>דוגמה 1: בחרת זוג 7, אם עלה בהגרלה 77K10 אז זכית פרס ראשון. דוגמה 2: בחרת זוג A, אם עלה בהגרלה A8AA אז זכית ב 3 פרס ראשון. דוגמה 3: בחרת זוג 9, אם עלה בהגרלה 9999 אז זכית ב 6 פרס ראשון.</li>
            </ul>
            {!isMobile && (
                <Box sx={{display: {xs:"none",md:"block"},textAlign:"center"}}>
                { gameNameHebrew && <Typography sx={{fontFamily: 'CustomFont'}}>{gameNameHebrew}</Typography>}
                <FormControl
                    required
                    error={hasError}
                >
                <Box sx={{margin:"auto",width:1000,display:"grid",gridAutoFlow:"column",padding:"6px"}}>
                {faceCard.map((face,index)=>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    key={index}
                    >
                    <Grid item xs={"auto"}>
                        <FormControlLabel
                        control={
                        <Box sx={{bgcolor:"white",width:"100px",margin:"auto",display:"grid",gridAutoFlow:"row",border:"1px black solid",borderRadius:"8px"}}>
                            <Typography variant='h6' textAlign={"center"}>{face}</Typography> 
                            <Checkbox key={index} checked={cards === `${face}`} onChange={handleChange} name={`${face}`} value={face}/>
                            {/* <Checkbox key={index} checked={cards[`H${face}`]} onChange={handleChange} name={`H${face}`} value={face}/> */}
                        </Box>
                        }
                        />
                    </Grid>
                </Grid>,
                )}
                </Box>
                {hasError && !errorEmpty && <FormHelperText sx={{textAlign:"center",margin:"auto",padding:"4px",color:"red",fontSize:"1rem"}}>ניתן לבחור עד 4 קלפים</FormHelperText>}
                </FormControl>
                </Box>
            )}

            {isMobile && (
                <Box sx={{display: {xs:"block",md:"none"}}}>
                    { gameNameHebrew && <Typography sx={{fontFamily: 'CustomFont',textAlign:"center"}}>{gameNameHebrew}</Typography>}
                    <Box sx={{margin:"auto",width:"100%",height:"auto",display:"grid",gridAutoFlow:"row",bgcolor:"rgb(236,236,236)",paddingTop:"1rem",paddingBottom:"1rem",marginBottom:"1rem"}}>
                    <FormControl
                    required
                    error={hasError}
                    component="fieldset"
                    variant="standard"
                    >
                    <Box sx={{textAlign:"center",margin:"auto",width:"100%",height:"auto",display:"grid",gridAutoFlow:"column",bgcolor:""}}>
                    {faceCard.map((face,index)=>
                    <FormControlLabel key={index} sx={{margin:"auto",width:"fit-content"}}
                    control={
                        <Box sx={{bgcolor:"white",width:"auto",margin:"auto",display:"grid",gridAutoFlow:"row",border:"1px black solid",borderRadius:"8px"}}>
                            <Typography variant='h6' textAlign={"center"}>{face}</Typography> 
                            <Checkbox key={index} checked={cards === `${face}`} onChange={handleChange} name={`${face}`} value={face}/>
                        </Box>
                    }
                    />
                    )}
                    
                    </Box>
                    {hasError && !errorEmpty && <FormHelperText sx={{textAlign:"center",margin:"auto",padding:"4px",color:"red",fontSize:"1rem"}}>ניתן לבחור עד 4 קלפים</FormHelperText>}
                    </FormControl>
                    </Box>
                </Box>
            )}

            { (gameName==="Chance4" || gameName==="ChanceAll") && 
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Fragment>
                <Typography>סוג משחק:</Typography>
                <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="select-price-radio-buttons-group-label"
                    name="select-price-radio-buttons-group"
                    // value={gameName}
                    value={allOR4}
                    onChange={handleRadioChange}
                >
                    <FormControlLabel value={"Chance4"} control={<Radio />} label="צאנס 4" />
                    <FormControlLabel value={"ChanceAll"} control={<Radio />} label="רב צאנס" />
                </RadioGroup>
                </FormControl>
                </Fragment>
            </Box>
            }
            <Box sx={{display: "flex", flexDirection: "column", alignItems: {xs:"center",md:"flex-end"} }}>
                <Typography fontFamily="CustomFont">{selectedCards.length} : סה״כ צירופים</Typography>
                <Typography fontFamily="CustomFont">{5*selectedCards.length} : מחיר לדוגמה 5₪</Typography>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: {xs:"center",md:"flex-end"} }}>
            {userContext ? <Button disabled={hasError || errorEmpty} variant="contained" sx={buttonStyles.rotation} onClick={()=>addToCart()}>שלח לסל</Button>
            :
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <LoginModal text={"שלח לסל"}/>
            </Box>}
            </Box>
            </Fragment>
          </Box>
        </Collapse>
      </Box>
    );
  }
}

export default SendPair;