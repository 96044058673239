import * as React from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import cardStyles from '../../cardStyles';

import { useSelector } from 'react-redux'
import { selectResultHistory } from '../../slices/resultHistory';
import SendPair from '../sendTicket/sendPair';

export default function PairAverage(props) {

  const [maxDiffs4, setMaxDiffs4] = useState(props.maxDiffs4);
  const [maxDiffs3, setMaxDiffs3] = useState(props.maxDiffs3);
  const [difference, setDifference] = useState(props.difference);
  const [gameType, setGameType] = useState(props.gameType);
  const [PairFace, setPairFace] = useState({
    '7': { indx: [], count: [], highestDifference: 0, lastDraw: 0, drawFrequency: 0 },
    '8': { indx: [], count: [], highestDifference: 0, lastDraw: 0, drawFrequency: 0 },
    '9': { indx: [], count: [], highestDifference: 0, lastDraw: 0, drawFrequency: 0 },
    '10': { indx: [], count: [], highestDifference: 0, lastDraw: 0, drawFrequency: 0 },
    'J': { indx: [], count: [], highestDifference: 0, lastDraw: 0, drawFrequency: 0 },
    'Q': { indx: [], count: [], highestDifference: 0, lastDraw: 0, drawFrequency: 0 },
    'K': { indx: [], count: [], highestDifference: 0, lastDraw: 0, drawFrequency: 0 },
    'A': { indx: [], count: [], highestDifference: 0, lastDraw: 0, drawFrequency: 0 }
  });

  // useEffect(()=>{
  //   console.log(PairFace);
  // },[PairFace])


  const resultHistory = useSelector(selectResultHistory)
    // const [spade, setSpade] = useState(props.spade)
    // const [heart, setHeart] = useState(props.heart)
    // const [diamond, setDiamond] = useState(props.diamond)
    // const [club, setClub] = useState(props.club)
    // const [table, setTable] = useState([])
    // const [tableIndex, setTableIndex] = useState([])
    const [lastTime, setLastTime] = useState([])
    const [tableRest, setTableRest] = useState([])

  useEffect(()=>{
    setMaxDiffs4(props.maxDiffs4);
    setMaxDiffs3(props.maxDiffs3);
    setDifference(props.difference);
    setGameType(props.gameType);
  },[props.maxDiffs4,props.maxDiffs3,props.maxDiffs2,props.difference,props.gameType])

  function test(ary) {
    var newA = [];
    for (var i = 1; i < ary.length; i++)  newA.push(ary[i] - ary[i - 1])
      return Math.max.apply(null, newA);
  }

  function searchDraw(pairface) {
      let count = [];
      let indx = [];
      setTableRest([]);
      const lastDraw = resultHistory?.Draw[0];
      const totalDraws = difference?.length; // Assuming resultHistory.Draw contains all draw indices

      //difference is the range to search in
      const processCombination = (cardType1, cardType2) => {
          for (let i = 0; i < difference?.length; ++i) {
              if (resultHistory?.[cardType1][i] === pairface && resultHistory?.[cardType2][i] === pairface) {
                  if (indx.length === 0) {
                      setLastTime(i);
                      indx.push(lastDraw - i);
                  } else {
                      indx.push(lastDraw - i);
                      count.push(indx[indx.length - 2] - indx[indx.length - 1]);
                  }
              }
          }
      };

      processCombination('Spade', 'Heart');
      processCombination('Spade', 'Diamond');
      processCombination('Spade', 'Club');
      processCombination('Heart', 'Diamond');
      processCombination('Heart', 'Club');
      processCombination('Diamond', 'Club');

      setPairFace(prevState => ({
        ...prevState,
        [pairface]: {
          ...prevState[pairface],
          indx: indx,
          count: count
        }
      }));

      // setTable(count);
      // setTableIndex(indx);

      // console.log(count);
      // console.log(indx);
      // console.log(totalDraws);
      // console.log(indx.length);
      // console.log("Pair is: "+pairface);

      // highest value of the count array
      const highestValueCount = Math.max(...count);
      // console.log("Highest Value Count:", highestValueCount);

      // get the highest value from the indx array
      const highestValueIndx = Math.max(...indx);
      // console.log("Highest Value Indx:", highestValueIndx);

      //sort the indx array highest to lowest
      indx.sort((a, b) => b - a);
      // console.log("Indx Array Sorted:", indx);

      // what was the highest difference between consecutive draws in the sorted indx array
      let highestDifference = 0;
      for (let i = 1; i < indx.length; i++) {
          const difference = indx[i - 1] - indx[i]; // Adjusted to use sorted order
          if (difference > highestDifference) {
              // console.log(indx[i]);
              // console.log("difference: "+difference);
              highestDifference = difference;
          }
      }
      
      // console.log("Highest Difference:", highestDifference);
      setPairFace(prevState => ({
        ...prevState,
        [pairface]: {
          ...prevState[pairface],
          highestDifference: highestDifference
        }
      }));

      //the highest value is when was the last time the pair was drawn
      const recentDraw = resultHistory?.Draw[0];
      const LastDrawPair = recentDraw - highestValueIndx;
      // console.log("Last Time Pair Was Drawn:", LastDrawPair);
      setPairFace(prevState => ({
        ...prevState,
        [pairface]: {
          ...prevState[pairface],
          lastDraw: LastDrawPair
        }
      }));

      // Calculate draw average
      const drawFrequency = Math.round(totalDraws / indx.length);
      // console.log(`Draw Frequency: ${drawFrequency}`);
      setPairFace(prevState => ({
        ...prevState,
        [pairface]: {
          ...prevState[pairface],
          drawFrequency: drawFrequency
        }
      }));

      // CreateTable({
      //     spadeData: count,
      //     heartData: indx,
      //     drawNumber: indx.length,
      //     difference: count,
      //     drawFrequency: drawFrequency  // Pass this to CreateTable if needed
      // });
    }

    useEffect(()=>{
      searchDraw("7");
      searchDraw("8");
      searchDraw("9");
      searchDraw("10");
      searchDraw("J");
      searchDraw("Q");
      searchDraw("K");
      searchDraw("A");
      // console.log(difference.length);
    },[difference])

  return (
    <Fragment>
      {gameType === 4 &&
      <Box sx={{width:{xs:"90%",md:"60%"},display:"grid",gridTemplateColumns:{xs: "1fr 1fr",md:"1fr 1fr 1fr 1fr"},margin:"auto",padding:"1rem",
      textAlign:"center",columnGap:{xs:"1rem",md:"1rem"},rowGap:"1rem"}}>
        
        {/* loop through PairFace and create a card for each pair */}
        {Object.keys(PairFace).map((pairface) => (
          <Card key={pairface} sx={cardStyles.averageModal}>
            <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
              <Typography sx={{fontFamily:"CustomFont", direction: "rtl"}}>זוג {pairface}</Typography>
              <Typography sx={{fontFamily:"CustomFont"}}>יצא {PairFace[pairface].count.length} מ {difference?.length}</Typography>
              <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {PairFace[pairface].drawFrequency}</Typography>
              <Typography sx={{fontFamily:"CustomFont",color: PairFace[pairface].lastDraw > PairFace[pairface].drawFrequency ? "green" : "red"}}>פעם אחרונה: {PairFace[pairface].lastDraw}</Typography>
              <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {PairFace[pairface].highestDifference}</Typography>
              <SendPair sendButton={true} pairface={pairface}/>
              <Typography sx={{fontFamily:"CustomFont", fontSize:"smaller", color:"gray",marginTop:"0.5rem"}}>סה״כ צירופים: {8*8*6}</Typography>
              <Typography sx={{fontFamily:"CustomFont", fontSize:"smaller", color:"gray"}}>מחיר לדוגמה 5₪: {8*8*6*5}</Typography>
              {/* add total price,about section */}
            </Box>
          </Card>
        ))}

      </Box>
      }

      {gameType === 3 && 
      <Box sx={{width:{xs:"90%",md:"60%"},display:"grid",gridTemplateColumns:{xs: "1fr 1fr",md:"1fr 1fr 1fr 1fr"},margin:"auto",padding:"1rem",
      textAlign:"center",columnGap:{xs:"1rem",md:"1rem"},rowGap:"1rem"}}>  
        
        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מתחת 500</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs3.maxUnder500.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs3.maxUnder500.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs3.maxUnder500[0] > Math.round(difference?.length / maxDiffs3.maxUnder500.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs3.maxUnder500[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs3.maxUnder500)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 500-1000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs3.maxBet5001K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs3.maxBet5001K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs3.maxBet5001K[0] > Math.round(difference?.length / maxDiffs3.maxBet5001K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs3.maxBet5001K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs3.maxBet5001K)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 1000-2000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs3.maxBet12K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs3.maxBet12K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs3.maxBet12K[0] > Math.round(difference?.length / maxDiffs3.maxBet12K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs3.maxBet12K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs3.maxBet12K)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מעל 2000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs3.maxAbove2K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs3.maxAbove2K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs3.maxAbove2K[0] > Math.round(difference?.length / maxDiffs3.maxAbove2K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs3.maxAbove2K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs3.maxAbove2K)}</Typography>
        </Box>
        </Card>
      </Box>
      }
    </Fragment>
  );
}