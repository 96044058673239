import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import AverageModal from './averageModal';
import BetweenModal from './betweenModal';
import PairAverage from './pairAverage';
// import OtherStats from './otherStats';

function TableCreate(props) {
    // Props are: {spadeData,heartData,diamondData,clubData,drawNumber,lastTime,difference}

     // Stikcy Header Table / Pagination MUI Start
     const [page, setPage] = useState(0);
     const [rowsPerPage, setRowsPerPage] = useState(10);
     const handleChangePage = (event, newPage) => {
       setPage(newPage);
     };
     const handleChangeRowsPerPage = (event) => {
       setRowsPerPage(+event.target.value);
       setPage(0);
     };
     // Stikcy Header Table / Pagination MUI Ends

    const [spade, setSpade] = useState(props.spadeData);
    const [heart, setHeart] = useState(props.heartData);
    const [diamond, setDiamond] = useState(props.diamondData);
    const [club, setClub] = useState(props.clubData);
    const [drawNumber, setDrawNumber] = useState(props.drawNumber);
    const [lastTime, setLastTime] = useState(props.lastTime);
    const [difference, setDifference] = useState(props.difference);
    const [showStat, setShowStat] = useState(true || props.showStat);
    const [gameType, setGameType] = useState(0);
    const [otherStats, setOtherStats] = useState(props.otherStats)
    const [sortDirection, setSortDirection] = useState('asc'); // State to track sort direction

    useEffect(()=>{
        setSpade(props.spadeData)
        setHeart(props.heartData)
        setDiamond(props.diamondData)
        setClub(props.clubData)
        setDrawNumber(props.drawNumber)
        setLastTime(props.lastTime)
        setDifference(props.difference)
        setOtherStats(props.otherStats)
        if (props.showStat === false){
            setShowStat(false)
        }
        // setShowStat(props.showStat || true)
    },[props.spadeData,props.heartData,props.diamondData,props.clubData,props.drawNumber,props.lastTime,props.difference,props.showStat,props.otherStats])

    function cost(price,difference,combinations){
        const payout4=2000;
        const payout3=300;
        const payout2=30;
        const payout1=5; 
        let totalCost =0;

        if(gameType===4){
            for(let i=1;i<=difference;i++){
                let costPerDraw = price*combinations;
                console.log(costPerDraw);
                totalCost += costPerDraw;
                if(totalCost>=(2000*price)){
                    price=price*2;
                }
            }
        }
        console.log("difference is: "+difference);
        console.log("Total Cost: "+totalCost);
        console.log("Win: "+price*payout4);
        console.log("Profit: "+((price*payout4)-totalCost));
    }

    useEffect(()=>{
        // cost(5,70,147);
    },[])
    
    useEffect(() => {
        typeofgame();
        // console.log("here");
    }, [spade, heart, diamond, club]);

    function typeofgame(){
        // let gametype=0;
        //Chance 1
        if( (spade && !heart && !diamond && !club) ||
            (!spade && heart && !diamond && !club) ||
            (!spade && !heart && diamond && !club) ||
            (!spade && !heart && !diamond && club) )
        {
            setGameType(1);
            // gametype=1;
            // return gametype;
        }
        //Chance 2
        if( (spade && heart && !diamond && !club) ||
            (spade && !heart && diamond && !club) ||
            (spade && !heart && !diamond && club) ||
            (!spade && heart && diamond && !club) ||
            (!spade && heart && !diamond && club) ||
            (!spade && !heart && diamond && club) )
        {
            setGameType(2);
        }
         //Chance 3
         if( (spade && heart && diamond && !club) ||
         (spade && heart && !diamond && club) ||
         (spade && !heart && diamond && club) ||
         (!spade && heart && diamond && club) )
        {
            setGameType(3);
        }
        //Chance 4
        if(spade && heart && diamond && club){
            setGameType(4);
        }
    }

    function test(ary) {
        // console.log("Arr here");
        // console.log(ary);
        var newA = [];
        for (var i = 1; i < ary.length; i++)  newA.push(ary[i] - ary[i - 1])
            return Math.max.apply(null, newA);
    }

    useEffect(()=>{
        // console.log("range is: "+difference?.length);
        // if(gameType===0 && !difference){
        //     typeofgame()
        // }
        // if(gameType===0 && difference){
        //     typeofgame()
        // }
        if(gameType===2 && difference){
            const ranges = [
                { max: 50, name: 'maxUnder50' },
                { max: 100, name: 'maxBet50100' },
                { max: 200, name: 'maxBet12' },
                { max: Infinity, name: 'maxAbove200' },
              ];
              const stateVars = {};
              for (const range of ranges) {
                stateVars[range.name] = [];
              }
              for (let i = 0; i < difference.length; i++) {
                const diff = difference[i];
                for (let j = 0; j < ranges.length; j++) {
                  if (diff < ranges[j].max) {
                    stateVars[ranges[j].name].push(i);
                    break;
                  }
                }
              }
            setMaxDiffs2(prevState => ({ ...prevState, ...stateVars }));
        }
        if(gameType===3 && difference){
            const ranges = [
                { max: 500, name: 'maxUnder500' },
                { max: 1000, name: 'maxBet5001K' },
                { max: 2000, name: 'maxBet12K' },
                { max: Infinity, name: 'maxAbove2K' },
              ];
              const stateVars = {};
              for (const range of ranges) {
                stateVars[range.name] = [];
              }
              for (let i = 0; i < difference.length; i++) {
                const diff = difference[i];
                for (let j = 0; j < ranges.length; j++) {
                  if (diff < ranges[j].max) {
                    stateVars[ranges[j].name].push(i);
                    break;
                  }
                }
              }
              setMaxDiffs3(prevState => ({ ...prevState, ...stateVars }));
        }
        if (gameType === 4 && difference) {
            const ranges = [
              { max: 2000, name: 'maxUnder2K' },
              { max: 5000, name: 'maxBet25K' },
              { max: 6000, name: 'maxBet56K' },
              { max: 7000, name: 'maxBet67K' },
              { max: 8000, name: 'maxBet78K' },
              { max: 9000, name: 'maxBet89K' },
              { max: 10000, name: 'maxBet910K' },
              { max: Infinity, name: 'maxAbove10K' },
            ];
            const stateVars = {};
            for (const range of ranges) {
              stateVars[range.name] = [];
            }
            for (let i = 0; i < difference.length; i++) {
              const diff = difference[i];
              for (let j = 0; j < ranges.length; j++) {
                if (diff < ranges[j].max) {
                  stateVars[ranges[j].name].push(i);
                  break;
                }
              }
            }
            setMaxDiffs4(prevState => ({ ...prevState, ...stateVars }));
        }
        //Chance 4 - Pairs
        if (gameType === 4 && difference && otherStats) {
          const ranges = [
            { max: 7, name: 'maxPair7' },
            { max: 8, name: 'maxPair8' },
            { max: 9, name: 'maxPair9' },
            { max: 10, name: 'maxPair10' },
            { max: 11, name: 'maxPairJ' },
            { max: 12, name: 'maxPairQ' },
            { max: 13, name: 'maxPairK' },
            { max: 14, name: 'maxPairA' },
          ];
          const stateVars = {};
          for (const range of ranges) {
            stateVars[range.name] = [];
          }
          for (let i = 0; i < difference.length; i++) {
            const diff = difference[i];
            for (let j = 0; j < ranges.length; j++) {
              if (diff < ranges[j].max) {
                stateVars[ranges[j].name].push(i);
                break;
              }
            }
          }
          // setMaxDiffsPairs(prevState => ({ ...prevState, ...stateVars }));
      }
    },[difference,gameType])


    // Chance 2
    const [diff2, setDiff2] = useState({
        under50: [],
        bet50100: [],
        bet12: [],
        above200: []
    });

    const [maxDiffs2, setMaxDiffs2] = useState({
        maxUnder50: [],
        maxBet50100: [],
        maxBet12: [],
        maxAbove200: []
    });

    // Chance 3
    const [diff3, setDiff3] = useState({
        under500: [],
        bet5001K: [],
        bet12K: [],
        above2K: []
    });

    const [maxDiffs3, setMaxDiffs3] = useState({
        maxUnder500: [],
        maxBet5001K: [],
        maxBet12K: [],
        maxAbove2K: []
    });

    // Chance 4
    const [diff4, setDiff4] = useState({
        under2K: [],
        bet25K: [],
        bet56K: [],
        bet67K: [],
        bet78K: [],
        bet89K: [],
        bet910K: [],
        above10K: []
    });

    const [maxDiffs4, setMaxDiffs4] = useState({
        maxUnder2K: [],
        maxBet25K: [],
        maxBet56K: [],
        maxBet67K: [],
        maxBet78K: [],
        maxBet89K: [],
        maxBet910K: [],
        maxAbove10K: [],
    });

    useEffect(()=>{
        if(gameType===0){
            typeofgame()
        }
        //Chance 2
        if(gameType === 2){
            let under50=[];
            let bet50100=[];
            let bet12=[];
            let above200=[];
            for(let i=0;i<lastTime?.length;i++){
                if(lastTime[i]<=50){
                    under50.push(i);
                }
                if(lastTime[i]>50 && lastTime[i]<=100){
                    bet50100.push(i)
                }
                if(lastTime[i]>100 && lastTime[i]<=200){
                    bet12.push(i)
                }
                if(lastTime[i]>200){
                    above200.push(i)
                }
            }
            setDiff2(prevState => ({...prevState, under50: under50 }));
            setDiff2(prevState => ({...prevState, bet50100: bet50100 }));
            setDiff2(prevState => ({...prevState, bet12: bet12 }));
            setDiff2(prevState => ({...prevState, above200: above200 }));
        }
        //Chance 3
        if(gameType === 3){
            let under500=[];
            let bet5001K=[];
            let bet12K=[];
            let above2K=[];
            for(let i=0;i<lastTime?.length;i++){
                if(lastTime[i]<=500){
                    under500.push(i);
                }
                if(lastTime[i]>500 && lastTime[i]<=1000){
                    bet5001K.push(i)
                }
                if(lastTime[i]>1000 && lastTime[i]<=2000){
                    bet12K.push(i)
                }
                if(lastTime[i]>2000){
                    above2K.push(i)
                }
            }
            setDiff3(prevState => ({...prevState, under500: under500 }));
            setDiff3(prevState => ({...prevState, bet5001K: bet5001K }));
            setDiff3(prevState => ({...prevState, bet12K: bet12K }));
            setDiff3(prevState => ({...prevState, above2K: above2K }));
        }

        //Chance 4
        if(gameType === 4){
            let under2K=[];
            let bet25K=[];
            let bet56K=[];
            let bet67K=[];
            let bet78K=[];
            let bet89K=[];
            let bet910K=[];
            let above10K=[];
            for(let i=0;i<lastTime?.length;i++){
                if(lastTime[i]<=2000){
                    under2K.push(i);
                }
                if(lastTime[i]>2000 && lastTime[i]<=5000){
                    bet25K.push(i)
                }
                if(lastTime[i]>5000 && lastTime[i]<=6000){
                    bet56K.push(i)
                }
                if(lastTime[i]>6000 && lastTime[i]<=7000){
                    bet67K.push(i)
                }
                if(lastTime[i]>7000 && lastTime[i]<=8000){
                    bet78K.push(i)
                }
                if(lastTime[i]>8000 && lastTime[i]<=9000){
                    bet89K.push(i)
                }
                if(lastTime[i]>9000 && lastTime[i]<=10000){
                    bet910K.push(i)
                }
                if(lastTime[i]>10000){
                    above10K.push(i)
                }
                if(typeof lastTime[i] === "string"){
                    above10K.push(i)
                }
            }
            setDiff4(prevState => ({...prevState, under2K: under2K }));
            setDiff4(prevState => ({...prevState, bet25K: bet25K }));
            setDiff4(prevState => ({...prevState, bet56K: bet56K }));
            setDiff4(prevState => ({...prevState, bet67K: bet67K }));
            setDiff4(prevState => ({...prevState, bet78K: bet78K }));
            setDiff4(prevState => ({...prevState, bet89K: bet89K }));
            setDiff4(prevState => ({...prevState, bet910K: bet910K }));
            setDiff4(prevState => ({...prevState, above10K: above10K }));
        }
    },[lastTime,gameType])


    // useEffect(() => {
    //     if(maxDiffs2.maxUnder50.length >0){
    //         console.log("Max difference under 50: "+test(maxDiffs2.maxUnder50));
    //         console.log("Max difference bet 50-100: "+test(maxDiffs2.maxBet50100));
    //         console.log("Max difference bet 100-200: "+test(maxDiffs2.maxBet12));
    //         console.log("Max difference above 200: "+test(maxDiffs2.maxAbove200));
    //     }
    //     if(maxDiffs3.maxUnder500.length >0){
    //         console.log("Max difference under 500: "+test(maxDiffs3.maxUnder500));
    //         console.log("Max difference bet 500-1000: "+test(maxDiffs3.maxBet5001K));
    //         console.log("Max difference bet 1000-2000: "+test(maxDiffs3.maxBet12K));
    //         console.log("Max difference above 2000: "+test(maxDiffs3.maxAbove2K));
    //     }
    //     if (maxDiffs4.maxUnder2K.length > 0) {
    //         console.log("Max difference under 2K: " + test(maxDiffs4.maxUnder2K));
    //         console.log("Max difference bet 2-5K: " + test(maxDiffs4.maxBet25K));
    //         console.log("Max difference bet 5-6K: " + test(maxDiffs4.maxBet56K));
    //         console.log("Max difference bet 6-7K: " + test(maxDiffs4.maxBet67K));
    //         console.log("Max difference bet 7-8K: " + test(maxDiffs4.maxBet78K));
    //         console.log("Max difference bet 8-9K: " + test(maxDiffs4.maxBet89K));
    //         console.log("Max difference bet 9-10K: " + test(maxDiffs4.maxBet910K));
    //         console.log("Max difference above 10K: " + test(maxDiffs4.maxAbove10K));
    //       }   
    //   }, [maxDiffs4,maxDiffs3,maxDiffs2]);

    const styles = {
        container: { display: "flex", flexDirection: "column", alignItems: "center",justifyContent:"center" },
        icon: { fontSize: "3rem", marginBottom: "0.2rem" },
        iconRed: { fontSize: "3rem", marginBottom: "0.2rem",color:"red" }
    };

    // Function to toggle sort direction and sort data
    const toggleSortByDrawNumber = () => {
        const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newDirection);
        setDrawNumber(currentDrawNumbers => {
            const sorted = [...currentDrawNumbers].sort((a, b) => {
                if (newDirection === 'asc') {
                    return a - b;
                } else {
                    return b - a;
                }
            });
            return sorted;
        });
    };

  return (
    <>
    <Paper sx={{ 
      maxWidth: 1000, 
      width: { xs: '95%', md: 'auto' }, 
      margin: "auto", 
      overflow: 'hidden', 
      marginTop: "1rem", 
      marginBottom: "1rem", 
      boxShadow: 3, // Add shadow
      borderRadius: 2 // Add rounded corners
    }}>
      <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
        <Table stickyHeader sx={{ margin: "auto", boxShadow: 3, borderRadius: 2 }} aria-label="Result Table">
          <TableHead>
            <TableRow>
              {spade?.length > 0 &&
              <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                <div className="icon-container" style={styles.container}>
                  <span className="icon" style={styles.icon}>&#9824;</span>
                  <span>עלה</span>
                </div>
              </TableCell>}
              {heart?.length > 0 &&
              <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                <div className="icon-container" style={styles.container}>
                  <span className="icon" style={styles.iconRed}>&#9829;</span>
                  <span>לב</span>
                </div>
              </TableCell>}
              {diamond?.length > 0 &&
              <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                <div className="icon-container" style={styles.container}>
                  <span className="icon" style={styles.iconRed}>&#9830;</span>
                  <span>יהלום</span>
                </div>
              </TableCell>}
              {club?.length > 0 &&
              <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                <div className="icon-container" style={styles.container}>
                  <span className="icon" style={styles.icon}>&#9827;</span>
                  <span>תלתן</span>
                </div>
              </TableCell>}
              {drawNumber?.length > 0 && (
                    <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }} onClick={toggleSortByDrawNumber}>
                        מספר הגרלה {sortDirection === 'asc' ? '↑' : '↓'}
                    </TableCell>
                )}
              {lastTime?.length > 0 && <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>פעם אחרונה</TableCell>}
              {difference?.length > 0 && <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>הגרלות עד שיצא</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {(difference || lastTime || drawNumber)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                {spade?.length > 0 && <TableCell align="center">{spade.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}</TableCell>}
                {heart?.length > 0 && <TableCell align="center">{heart.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}</TableCell>}
                {diamond?.length > 0 && <TableCell align="center">{diamond.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}</TableCell>}
                {club?.length > 0 && <TableCell align="center">{club.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}</TableCell>}
                {drawNumber?.length > 0 && <TableCell align="center">{drawNumber.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}</TableCell>}
                {lastTime?.length > 0 && <TableCell align="center">{lastTime.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}</TableCell>}
                {difference?.length > 0 && <TableCell align="center">{difference.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index]}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(difference?.length > 10 || lastTime?.length > 10 || drawNumber?.length > 10 || page > 0) &&
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={difference?.length || lastTime?.length || drawNumber?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="שורות בעמוד:"
      />
      }
    </Paper>
        {showStat && gameType === 4 && diff4 && difference &&
        <AverageModal maxDiffs4={maxDiffs4} difference={difference} gameType={gameType}/>
        // && <PairAverage maxDiffs4={maxDiffs4} difference={difference} gameType={gameType}/>        
        }

        {showStat && gameType === 4 && diff4 && difference && otherStats && 
        <PairAverage maxDiffs4={maxDiffs4} difference={difference} gameType={gameType}/>
        } 
        {showStat && gameType === 3 && diff3 && difference &&
        <AverageModal maxDiffs3={maxDiffs3} difference={difference} gameType={gameType}/>
        }
        {showStat && gameType === 2 && diff2 && difference &&
        <AverageModal maxDiffs2={maxDiffs2} difference={difference} gameType={gameType}/>
        }

        {showStat && gameType === 4 && lastTime &&
        <BetweenModal gameType={gameType} diff4={diff4} lastTime={lastTime} spade={spade} heart={heart} diamond={diamond} club={club}/>
        }
        {showStat && gameType === 3 && lastTime &&
        <BetweenModal gameType={gameType} diff3={diff3} lastTime={lastTime} spade={spade} heart={heart} diamond={diamond} club={club}/>
        }
        {showStat && gameType === 2 && lastTime &&
        <BetweenModal gameType={gameType} diff2={diff2} lastTime={lastTime} spade={spade} heart={heart} diamond={diamond} club={club}/>
        }
        <hr style={{border:"1px solid black",width:"75%"}}/>
    </>
  )
}

export default TableCreate