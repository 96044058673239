import React, { Fragment, useState } from 'react'

import GitHubIcon from '@mui/icons-material/GitHub';
// import FacebookIcon from '@mui/icons-material/Facebook';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
// import InstagramIcon from '@mui/icons-material/Instagram';
import RoomIcon from '@mui/icons-material/Room';
// import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import styled from "styled-components";
import { mobile } from "../../constants/responsive";
import { Link } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
  
  const Container = styled.div`
    display: flex;
    background-color: black;
    color: white;
    width: 100%;
    font-family: CustomFont;
    ${mobile({ flexDirection: "column" })}
    a:visited {
      color: white;
    }
    a {
      color: white;
      text-decoration: none;
    }    
  `;

  
  const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
  `;
  
  const Logo = styled.h1``;
  
  const Desc = styled.p`
    margin: 20px 0px;
  `;
  
  const SocialContainer = styled.div`
    display: flex;
  `;
  
  const SocialIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    background-color: #${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  `;
  
  const Center = styled.div`
    flex: 1;
    padding: 20px;
    ${mobile({ display: "none" })}    
  `;

  
  const Title = styled.h3`
    margin-bottom: 30px;
  `;
  
  const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  `;
  
  const ListItem = styled.li`
    width: 50%;
    margin-bottom: 10px;
  `;
  
  const Right = styled.div`
    flex: 1;
    padding: 20px;
    ${mobile({ backgroundColor: "#000000" })}
  `;
  
  
  const ContactItem = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: ${(props) => (props.phone ? 'pointer' : 'default')};
  `;

  const StyledButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 60px;
  display: ${({ show }) => (show ? 'block' : 'none')};
  ${mobile({ bottom: "20px",right: "20px" })}
  z-index: 999;
  border: none;
  outline: none;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  &:hover {
    background-color: #555;
  }
`;
  
//   const Payment = styled.img`
//       width: 50%;
//   `;
  
export default function Footer() {

  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const text = '052-770-7942 hayekwisam@gmail.com';
  const phoneRegex = /(\d{3})-(\d{3})-(\d{4})/;
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
  const phoneMatch = text.match(phoneRegex);
  const emailMatch = text.match(emailRegex);

    return (
      <Fragment>
        <StyledButton show={showButton} onClick={handleButtonClick}>
        <ArrowUpwardIcon />
        </StyledButton>
        <Container>
        <Left>
            <Logo>Chance Analytics</Logo>
            <Desc>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don’t look even slightly believable.
            </Desc>
            <SocialContainer>
            {/* <SocialIcon color="3B5999">
                <FacebookIcon />
            </SocialIcon>
            <SocialIcon color="E4405F">
                <InstagramIcon />
            </SocialIcon>
            <SocialIcon color="55ACEE">
                <TwitterIcon />
            </SocialIcon> */}
            <SocialIcon color="000000">
                <a href="https://github.com/wisamhayek" target="_blank" rel="noopener noreferrer">
                    <GitHubIcon />
                </a>
            </SocialIcon>
            <SocialIcon color="0a66c2">
                <a href="https://www.linkedin.com/in/wisam-hayek" target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon />
                </a>
            </SocialIcon>
            </SocialContainer>
        </Left>
        <Center>
            <Title>קישורים</Title>
            <List>
            <ListItem><Link to={"/"}>בית</Link></ListItem>
            <ListItem><Link to={"/cart"}>סל</Link></ListItem>
            <ListItem><Link to={"/profile"}>חשבון שלי</Link></ListItem>
            <ListItem><Link to={"/send"}>שלח טופס</Link></ListItem>
            {/* <ListItem><Link to={"/send"}>הסבר</Link></ListItem> */}
            <ListItem><Link to={"/splits"}>זוגות</Link></ListItem>
            <ListItem><Link to={"/chance1"}>צאנס 1</Link></ListItem>
            <ListItem><Link to={"/groups"}>קבוצות</Link></ListItem>
            <ListItem><Link to={"/chance2"}>צאנס 2</Link></ListItem>
            <ListItem><Link to={"/chance2dif"}>ממוצע 2</Link></ListItem>
            <ListItem><Link to={"/chance3"}>צאנס 3</Link></ListItem>
            <ListItem><Link to={"/chance3dif"}>ממוצע 3</Link></ListItem>
            <ListItem><Link to={"/chance4"}>צאנס 4</Link></ListItem>
            <ListItem><Link to={"/chance4dif"}>ממוצע 4</Link></ListItem>
            </List>
        </Center>
        <Right>
            <Title>צור קשר</Title>
            <ContactItem>
            <RoomIcon style={{marginRight:"10px"}}/>Vancouver, Canada
            </ContactItem>
            <ContactItem phone onClick={() => window.open(`tel:${phoneMatch[0]}`, '_self')}>
            <PhoneIcon style={{marginRight:"10px"}}/> {phoneMatch[0]}
            </ContactItem>
            <ContactItem onClick={() => window.open(`mailto:${emailMatch[0]}`)}>
              <MailOutlineIcon style={{ marginRight: '10px' }} />
              {emailMatch[0]}
            </ContactItem>
            {/* <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" /> */}
        </Right>
        </Container>
      </Fragment>
    );
};
  