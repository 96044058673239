import { createSlice } from '@reduxjs/toolkit'

const initialState ={
    draw: null
}

export const resultHistorySlice = createSlice({
  name: 'resultHistory',
  initialState,
  reducers: {
    setHistory: (state,action)=>{
        state.draw = action.payload;
        // console.log(action.payload);
    }
  },
})

// Action creators are generated for each case reducer function
export const { setHistory } = resultHistorySlice.actions

// Selectors
export const selectResultHistory = (state) => state.history.draw;


export default resultHistorySlice.reducer