import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Collapse, Divider, List, ListItem, ListItemText } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as ROUTES from '../../constants/routes';
import { useContext } from 'react';
import { UserContext } from '../../App';
import backgroundImage1 from '../../assets/images/sidemenu1.jpg';
import backgroundImage2 from '../../assets/images/sidemenu2.jpg';
import backgroundImage3 from '../../assets/images/sidemenu3.jpg';
import backgroundImage4 from '../../assets/images/sidemenu4.jpg';
import backgroundImage5 from '../../assets/images/sidemenu5.jpg';
import backgroundImage6 from '../../assets/images/sidemenu6.jpg';
import backgroundImage7 from '../../assets/images/sidemenu7.jpg';


const adminMenu = [
  {
    title: 'Admin',
    url: ROUTES.ADMIN,
  },
  {
    title: 'DashBoard',
    url: ROUTES.STATIONHOME,
  }
];

const menuItems = [
    {
      title: 'בית',
      url: ROUTES.HOME,
    },
    {
      title: 'צאנס',
      submenu: [
        {
          title: 'צאנס 1',
          url: ROUTES.CHANCE1,
        },
        {
          title: 'צאנס 2',
          url: ROUTES.CHANCE2,
        },
        {
          title: 'צאנס 3',
          url: ROUTES.CHANCE3,
        },
        {
          title: 'צאנס 4',
          url: ROUTES.CHANCE4,
        },
      ],
    },
    {
      title: 'ממוצע',
      submenu: [
        {
          title: 'צאנס 2',
          url: ROUTES.CHANCE2DIF,
        },
        {
          title: 'צאנס 3',
          url: ROUTES.CHANCE3DIF,
        },
        {
          title: 'צאנס 4',
          url: ROUTES.CHANCE4DIF,
        },
      ],
    },
    {
      title: 'זוגות',
      url: ROUTES.SPLITS,
    },
    {
      title: 'שלח טופס',
      url: ROUTES.SEND,
    },
    {
      title: 'קבוצות',
      url: ROUTES.GROUPS,
    }
];


const MobileMenu = ({ toggleDrawer }) => {

    const {userContext, setUserContext} = useContext(UserContext);
    const isAdmin = userContext?.isAdmin;

    const navigate = useNavigate();
    const [openSubmenu, setOpenSubmenu] = useState(null);
    
    const handleSubmenuClick = (index) => {
      setOpenSubmenu(index === openSubmenu ? null : index);
    };

    const handleListItemClick = (url) => {
      toggleDrawer(false);
      navigate(url);
    };
    
    const renderMenuItem = (item, index) => {
      if (item.submenu) {
        const isOpen = index === openSubmenu;
        return (
          <div key={item.title}>
            <ListItem  onClick={()=>handleSubmenuClick(index)} sx={{flexDirection:"row-reverse"}}>
                <ListItemText sx={{textAlign:"end"}} primary={item.title} primaryTypographyProps={{ style: { fontFamily: "customFont" } }} />
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.submenu.map((subitem) => (
                  <ListItem
                    key={subitem.title}
                    onClick={() => handleListItemClick(subitem.url)}                    
                    sx={{ pl: 4 }}
                  >
                    <ListItemText sx={{textAlign:"end"}} primary={subitem.title} primaryTypographyProps={{ style: { fontFamily: "customFont" } }} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        );
      } else {
        return (
          <ListItem
            key={item.title}
            onClick={() => handleListItemClick(item.url)}
          >
            <ListItemText sx={{textAlign:"end"}} primary={item.title} primaryTypographyProps={{ style: { fontFamily: "customFont" } }} />
          </ListItem>
        );
      }
    };
  
    return (
      <Box
        sx={{
          width: 250,
          overflow: "scroll",
          padding: "1rem",
          // backgroundColor: "#013220", // Darker green background
          backgroundColor: "lightgray", 
          // backgroundImage: `url(${backgroundImage7})`,
          backgroundImage: `url(${backgroundImage3})`,
          backgroundSize: "cover", // Cover the entire box
          color: "white", // Text color
          height: "100%",
          textAlign: "end",
        }}
        role="presentation"
      >
        <Typography
          variant="h6"
          noWrap
          sx={{
            textAlign: "center",
            padding: "1rem",
            mr: 2,
            display: { xs: "block", md: "none" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "White",
            textDecoration: "none",
            margin: "auto",
          }}
        >
          LOGO
        </Typography>
        {/* <Divider sx={{borderColor:"white"}} /> */}
        <List sx={{textAlign:"center"}}>
          {menuItems.map((item, index) => renderMenuItem(item, index))}
          {isAdmin && renderMenuItem(adminMenu[0],0)}
          {userContext?.stationOwner?.owner && renderMenuItem(adminMenu[1],0)}
        </List>
        {/* <Divider sx={{ marginTop: "1rem",borderColor:"white" }} /> */}
        {/* <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
          <Typography variant="h6" sx={{ color: "white", marginBottom: "0.5rem" }}>
            Card Suits
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
            <Typography variant="h4" sx={{ color: "red", textShadow: "2px 2px 4px #000000" }}>
              ♥
            </Typography>
            <Typography variant="h4" sx={{ color: "black", textShadow: "2px 2px 4px #FFFFFF" }}>
              ♠
            </Typography>
            <Typography variant="h4" sx={{ color: "red", textShadow: "2px 2px 4px #000000" }}>
              ♦
            </Typography>
            <Typography variant="h4" sx={{ color: "black", textShadow: "2px 2px 4px #FFFFFF" }}>
              ♣
            </Typography>
          </Box>
        </Box> */}
      </Box>
    );
  };

  export default MobileMenu;