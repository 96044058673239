import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectResultHistory } from '../slices/resultHistory';
import Button from '@mui/material/Button';
import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TableCreate from '../components/table/table2';
// import AddCartButton from '../components/search/addCartButton';
import AddCartButton from '../components/sendTicket/addCartButton';
import buttonStyles from '../buttonStyles'
import cardStyles from '../cardStyles';
// One Big Function!
// Takes probs as what to do:
// 1. type: last draw / difference
// 2. game type: chance 1 2 3 4

function SearchFunction(props) {
    
    const resultHistory = useSelector(selectResultHistory)
    const [spade, setSpade] = useState(props.spade)
    const [heart, setHeart] = useState(props.heart)
    const [diamond, setDiamond] = useState(props.diamond)
    const [club, setClub] = useState(props.club)
    const [table, setTable] = useState([])
    const [tableIndex, setTableIndex] = useState([])
    const [lastTime, setLastTime] = useState([])
    const [tableRest, setTableRest] = useState([])

    useEffect(()=>{
        setSpade(props.spade)
        setHeart(props.heart)
        setDiamond(props.diamond)
        setClub(props.club)
    },[props.spade,props.heart,props.diamond,props.club])

    useEffect(()=>{
        if(props.cleared == true){
            setSpade("");
            setHeart("");
            setDiamond("");
            setClub("");
            setTable([]);
            setTableIndex([]);
            setLastTime([]);
            props.setCleared(false);
            setTableRest([]);
        }
    },[props.cleared])


    function searchDraw(){

        let count=[];
        let indx =[];
        setTableRest([]);
        const lastDraw = [resultHistory?.Draw[0]];

        // Chance 1 - Spade
        if(club==="" && heart==="" && diamond===""){
            for (let i = 0; i < resultHistory?.Spade?.length; ++i){
                if (resultHistory?.Spade[i] === spade[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }

        // Chance 1 - Heart
        if(club==="" && spade==="" && diamond===""){
            for (let i = 0; i < resultHistory?.Heart?.length; ++i){
                if (resultHistory?.Heart[i] === heart[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }

        // Chance 1 - Diamond
        if(spade==="" && heart==="" && club===""){
            for (let i = 0; i < resultHistory?.Diamond?.length; ++i){
                if (resultHistory?.Diamond[i] === diamond[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }

        // Chance 1 - Club
        if(spade==="" && heart==="" && diamond===""){
            // let result1 = resultHistory?.Club.findIndex(result => result == heart);
            // console.log("Last Draw: "+result1);
            // resultLastDraw =resultHistory?.Draw[0]-result1;
            // console.log(resultLastDraw);
            for (let i = 0; i < resultHistory?.Club?.length; ++i){
                if (resultHistory?.Club[i] === club[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }


        // Chance 2 - 1 Spade Heart
        if(diamond==="" && club===""){
            // let count = 0;
            for (let i = 0; i < resultHistory?.Spade?.length; ++i){
                if (resultHistory?.Spade[i] === spade[0] && resultHistory?.Heart[i] === heart[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }

        // Chance 2 - 2 Spade Diamond
        if(heart==="" && club===""){
            for (let i = 0; i < resultHistory?.Spade?.length; ++i){
                if (resultHistory?.Spade[i] === spade[0] && resultHistory?.Diamond[i] === diamond[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }
        
        // Chance 2 - 3 Spade Club
        if(heart==="" && diamond===""){
            for (let i = 0; i < resultHistory?.Spade?.length; ++i){
                if (resultHistory?.Spade[i] === spade[0] && resultHistory?.Club[i] === club[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }

        // Chance 2 - 4 Heart Diamond
        if(spade==="" && club===""){
            for (let i = 0; i < resultHistory?.Diamond?.length; ++i){
                if (resultHistory?.Heart[i] === heart[0] && resultHistory?.Diamond[i] === diamond[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }

        // Chance 2 - 5 Heart Club
        if(spade==="" && diamond===""){
            for (let i = 0; i < resultHistory?.Heart?.length; ++i){
                if (resultHistory?.Heart[i] === heart[0] && resultHistory?.Club[i] === club[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }

        // Chance 2 - 6 Diamond Club
        if(spade==="" && heart===""){
            for (let i = 0; i < resultHistory?.Diamond?.length; ++i){
                if (resultHistory?.Diamond[i] === diamond[0] && resultHistory?.Club[i] === club[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }

        // Chance 3 - 1 Spade Heart Diamond
        if(club===""){
            for (let i = 0; i < resultHistory?.Spade?.length; ++i){
                if (resultHistory?.Spade[i] === spade[0] && resultHistory?.Heart[i] === heart[0] && resultHistory?.Diamond[i] === diamond[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }
        
        // Chance 3 - 2 Spade Heart Club
        if(diamond===""){
            for (let i = 0; i < resultHistory?.Spade?.length; ++i){
                if (resultHistory?.Spade[i] === spade[0] && resultHistory?.Heart[i] === heart[0] && resultHistory?.Club[i] === club[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }

        // Chance 3 - 3 Spade Diamond Club
        if(heart===""){
            for (let i = 0; i < resultHistory?.Spade?.length; ++i){
                if (resultHistory?.Spade[i] === spade[0] && resultHistory?.Diamond[i] === diamond[0] && resultHistory?.Club[i] === club[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }

         // Chance 3 - 4 Heart Diamond Club
         if(spade===""){
            for (let i = 0; i < resultHistory?.Heart?.length; ++i){
                if (resultHistory?.Heart[i] === heart[0] && resultHistory?.Diamond[i] === diamond[0] && resultHistory?.Club[i] === club[0]){
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            setTable(count);
            setTableIndex(indx);
        }

        // Chance 4
        else{
            for (let i = 0; i < resultHistory?.Spade?.length; ++i){
                if (resultHistory?.Spade[i] === spade[0] && resultHistory?.Heart[i] === heart[0] && resultHistory?.Diamond[i] === diamond[0] && resultHistory?.Club[i] === club[0] ){
                    // If didnt find that means it's more than 25K draw..add if statment here
                    // if(count.length === 0){
                    //     count.push(i);
                    //     indx.push(lastDraw-i);
                    // }else{
                    //     count.push(i-count[count.length - 1])
                    //     indx.push(lastDraw-i);
                    // }
                    // break;
                    if(indx.length === 0){
                        setLastTime(i)
                        indx.push(lastDraw-i);
                    }else{
                        indx.push(lastDraw-i);
                        count.push(indx[indx.length-2]-indx[indx.length-1]);
                    }
                }
            }
            count.push(null);
            indx.push(null);
            setTable(count);
            setTableIndex(indx);
        }
    }

    useEffect(()=>{
        // There is a bug here when page re-renders
        setTableRest([])
        // This might fix it
        tableIndex.map((data,index)=>{
            const lastDraw = resultHistory?.Draw[0];
            let r = [resultHistory?.Spade[lastDraw-data],resultHistory?.Heart[lastDraw-data],resultHistory?.Diamond[lastDraw-data],resultHistory?.Club[lastDraw-data]]
            setTableRest((current => [...current, r]));
        })
    },[tableIndex])

    // useEffect(()=>{
    //  console.log(tableIndex);
    //  console.log(tableRest);
    //  console.log(table);
    // },[tableIndex,tableRest,table])

  return (
    <>
    {/* <Box sx={{textAlign:"center",display:"flex"}}> */}
    <Box sx={{textAlign:"center",margin:"auto",padding:{xs:"1rem",md:"1rem"},display:"flex",flexDirection:"column",columnGap: "1rem"}}>
        <Button sx={buttonStyles.search} variant="contained" onClick={()=>{
            searchDraw();
        }}>חפש</Button>
        <div style={{height:"1rem"}}></div>

        <AddCartButton spade={spade} heart={heart} diamond={diamond} club={club} setCleared={props.setCleared} funcClear={props.funcClear}/>

        {/* <Button sx={{margin:"auto",textAlign:"center"}} variant="contained" onClick={()=>{
            props.funcClear();
        }}>Clear</Button> */}
    </Box>

    {tableIndex.length>0 &&
    <Fragment>
    <Card sx={cardStyles.search}>
      <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", gap:"2rem"}}>
        <Typography textAlign={"right"} padding="4px" fontFamily={"CustomFont"}> {props.spade} :עלה</Typography>
        <Typography textAlign={"right"} padding="4px" fontFamily={"CustomFont"}> {props.heart} :לב</Typography>
        <Typography textAlign={"right"} padding="4px" fontFamily={"CustomFont"}> {props.diamond} :יהלום</Typography>
        <Typography textAlign={"right"} padding="4px" fontFamily={"CustomFont"}> {props.club} :תלתן</Typography>
      </Box>
      <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", gap:"1rem", direction:"rtl"}}>
        <Box sx={{display:"flex", justifyContent:"space-evenly", width:"100%"}}>
          <Typography textAlign={"center"} padding="4px" fontFamily={"CustomFont"}>פעם אחרונה</Typography>
          <Typography textAlign={"center"} padding="4px" fontFamily={"CustomFont"}>כמה פעמים</Typography>
          <Typography textAlign={"center"} padding="4px" fontFamily={"CustomFont"}>הפרש הכי גדול</Typography>
        </Box>
        <Box sx={{display:"flex", justifyContent:"space-evenly", width:"100%"}}>
          <Typography textAlign={"center"} padding="4px" fontFamily={"CustomFont"}>{lastTime}</Typography>
          <Typography textAlign={"center"} padding="4px" fontFamily={"CustomFont"}>{table.length}</Typography>
          <Typography textAlign={"center"} padding="4px" fontFamily={"CustomFont"}>{Math.max(...table)}</Typography>
        </Box>
      </Box>
    </Card>
    </Fragment>
    }

    {tableIndex.length>1 &&
    <TableCreate
        spadeData={()=>{
            let first = [];
            for(let i=0;i<tableRest.length;i++){                
                first.push(tableRest[i][0]);
            }
            return first;
        }}

        heartData={()=>{
            let first = [];
            for(let i=0;i<tableRest.length;i++){                
                first.push(tableRest[i][1]);
            }
            return first;
        }}

        diamondData={()=>{
            let first = [];
            for(let i=0;i<tableRest.length;i++){                
                first.push(tableRest[i][2]);
            }
            return first;
        }}

        clubData={()=>{
            let first = [];
            for(let i=0;i<tableRest.length;i++){                
                first.push(tableRest[i][3]);
            }
            return first;
        }}

        drawNumber={tableIndex}
        difference={table}
        showStat={false}
    />
    }
    </>
  )
}

export default SearchFunction