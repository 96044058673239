import * as React from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { useEffect } from 'react';
import axios from '../../constants/axiosInstance'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { addText } from '../../constants/printTickets';
import { CartContext, UserContext } from '../../App';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import LoginModal from '../navBar/loginModal';
import buttonStyles from '../../buttonStyles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: "100%", md: "50%"},
  // height: {xs: "auto", md: "auto"},
  // height: "100vh",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  padding: {xs:0,md: "1rem"},
  borderRadius: "1rem",
  // marginTop: {xs: "auto", md: "auto"},
  // marginTop:"auto",
};

export default function PrintPages(props) {

    const {userContext, setUserContext} = useContext(UserContext);
    const {cartContext, setCartContext} = useContext(CartContext);
    const ownerid = userContext?.id;
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [summary, setSummary] = useState(props.sum);
    const [spade, setSpade] = useState(props.spade);
    const [heart, setHeart] = useState(props.heart);
    const [diamond, setDiamond] = useState(props.diamond);
    const [club, setClub] = useState(props.club);
    const [gameType, setGameType] = useState(0);
    const [gameName, setGameName] = useState("");
    const [printTickets, setPrintTickets] = useState([]);


    useEffect(()=>{
        setSummary(props.sum);
        setSpade(props.spade);
        setHeart(props.heart);
        setDiamond(props.diamond);
        setClub(props.club);
    },[props.sum,props.spade,props.heart,props.diamond,props.club])

    useEffect(()=>{
        // console.log(printTickets);
        if(printTickets.length > 0){
            addText(printTickets,gameName);
        }
    },[printTickets])


    function typeofgame(){
        //Chance 1
        if( (spade && !heart && !diamond && !club) ||
            (!spade && heart && !diamond && !club) ||
            (!spade && !heart && diamond && !club) ||
            (!spade && !heart && !diamond && club) )
        {
            setGameType(1);
            setGameName("Chance1");
        }
        //Chance 2
        if( (spade && heart && !diamond && !club) ||
            (spade && !heart && diamond && !club) ||
            (spade && !heart && !diamond && club) ||
            (!spade && heart && diamond && !club) ||
            (!spade && heart && !diamond && club) ||
            (!spade && !heart && diamond && club) )
        {
            setGameType(2);
            setGameName("Chance2");
        }
         //Chance 3
         if( (spade && heart && diamond && !club) ||
         (spade && heart && !diamond && club) ||
         (spade && !heart && diamond && club) ||
         (!spade && heart && diamond && club) )
        {
            setGameType(3);
            setGameName("Chance3");
        }
        //Chance 4
        if(spade && heart && diamond && club){
            setGameType(4);
            setGameName("Chance4");
        }
    }

    
    function addEmptyStringToIndex(arr, x) {
        // loop through each sub-array in the input array
        for (let i = 0; i < arr.length; i++) {
            // add empty string at the x index
            arr[i].splice(x, 0, "");
        }
        // return the updated array
        return arr;
    }  

    useEffect(()=>{
        if(gameType===0){
            typeofgame()
        }
    },[gameType])

    function howMany(data,addIndex,option){
        // For Chance 4
        if(gameType === 4){
            // const reducedData = data.reduce((acc, curr) => {
            //     // const key = curr[0] + curr[1] + curr[2];
            //     const col4 = curr[3];
            //     const existingRow = acc.find(row => row[0] === curr[0] && row[1] === curr[1] && row[2] === curr[2]);
            //     if (existingRow) {
            //         existingRow[3].push(col4);
            //     } else {
            //         acc.push([curr[0], curr[1], curr[2], [col4]]);
            //     }
            //     return acc;

            const reducedData = data.reduce((acc, curr) => {
                const existingRow = acc.find(row => row[0][0] === curr[0] && row[1][0] === curr[1] && row[2][0] === curr[2]);
                if (existingRow) {
                  existingRow[3].push(curr[3]);
                } else {
                  acc.push([[curr[0]], [curr[1]], [curr[2]], [curr[3]]]);
                }
                return acc;
            }, []);
            // console.log(reducedData);
            if(option === "print"){
                setPrintTickets(reducedData);
            }
            if(option === "cart"){
                return reducedData;
            }

        // For Chance 3
        }else if(gameType === 3 ){
            // const reducedData = data.reduce((acc, curr) => {
            //     // const key = curr[0] + curr[1];
            //     const col3 = curr[2];
            //     const existingRow = acc.find(row => row[0] === curr[0] && row[1] === curr[1]);
            //     if (existingRow) {
            //         existingRow[2].push(col3);
            //     } else {
            //         acc.push([curr[0], curr[1], [col3]]);
            //     }
            //     return acc;
            const reducedData = data.reduce((acc, curr) => {
                const existingRow = acc.find(row =>row[0][0] === curr[0] && row[1][0] === curr[1]);
                if (existingRow) {
                  existingRow[2].push(curr[2]);
                } else {
                  acc.push([[curr[0]], [curr[1]], [curr[2]]]);
                }
                return acc;
            }, []);

            addEmptyStringToIndex(reducedData,addIndex);
            // console.log(reducedData);
            if(option === "print"){
                setPrintTickets(reducedData);
            }
            if(option === "cart"){
                return reducedData;
            }

        }else if(gameType === 2){
            // For Chance 2
            // const reducedData = data.reduce((acc, curr) => {
            // const col2 = curr[1];
            // const existingRow = acc.find(row => row[0] === curr[0]);
            // if (existingRow) {
            //     existingRow[1].push(col2);
            // } else {
            //     acc.push([curr[0], [col2]]);
            // }
            // console.log(acc);
            // return acc;

            const reducedData = data.reduce((acc, curr) => {
                const col2 = curr[1];
                const existingRow = acc.find(row => row[0][0] === curr[0]);
                if (existingRow) {
                  existingRow[1].push(col2);
                } else {
                  acc.push([[curr[0]], [col2]]);
                }
                // console.log(acc);
                return acc;

            }, []);
   
            if(!diamond && !club){
                addEmptyStringToIndex(reducedData,2);
                addEmptyStringToIndex(reducedData,3);
            }
            if(!heart && !club){
                addEmptyStringToIndex(reducedData,1);
                addEmptyStringToIndex(reducedData,3);
            }
            if(!heart && !diamond){
                addEmptyStringToIndex(reducedData,1);
                addEmptyStringToIndex(reducedData,2);
            }
            if(!spade && !club){
                addEmptyStringToIndex(reducedData,0);
                addEmptyStringToIndex(reducedData,3);
            }
            if(!spade && !diamond){
                addEmptyStringToIndex(reducedData,0);
                addEmptyStringToIndex(reducedData,2);
            }
            if(!spade && !heart){
                addEmptyStringToIndex(reducedData,0);
                addEmptyStringToIndex(reducedData,1);
            }
            // console.log(reducedData);
            if(option === "print"){
                setPrintTickets(reducedData);
            }
            if(option === "cart"){
                return reducedData;
            }

        }else if(gameType === 1){

            // For Chance 1
            if(!heart && !diamond && !club){
                addEmptyStringToIndex(data,1)
                addEmptyStringToIndex(data,2)
                addEmptyStringToIndex(data,3)
            }
            if(!spade && !diamond && !club){
                addEmptyStringToIndex(data,0)
                addEmptyStringToIndex(data,2)
                addEmptyStringToIndex(data,3)
            }
            if(!spade && !heart && !club){
                addEmptyStringToIndex(data,0)
                addEmptyStringToIndex(data,1)
                addEmptyStringToIndex(data,3)
            }
            if(!spade && !heart && !diamond){
                addEmptyStringToIndex(data,0)
                addEmptyStringToIndex(data,1)
                addEmptyStringToIndex(data,2)
            }
            // console.log(data);
            if(option === "print"){
                setPrintTickets(data);
            }
            if(option === "cart"){
                return data;
            }
        }
    }

    
    function printPages(){
        let arr=[];
        let addIndex = 0;

        if(spade === undefined){
            addIndex = 0;
        }if(heart === undefined){
            addIndex = 1;
        }if(diamond === undefined){
            addIndex = 2;
        }if(club === undefined){
            addIndex = 3;
        }
        
        summary.forEach(x => {
            arr.push([...(spade ? [spade[x]] : []),...(heart ? [heart[x]] : []),...(diamond ? [diamond[x]] : []), ...(club ? [club[x]] : []) ]);
        });
        // console.log(arr);
        howMany(arr,addIndex,"print");
    }

    function sendToCart(){
        let arr=[];
        let addIndex = 0;

        if(spade === undefined){
            addIndex = 0;
        }if(heart === undefined){
            addIndex = 1;
        }if(diamond === undefined){
            addIndex = 2;
        }if(club === undefined){
            addIndex = 3;
        }
        
        summary.forEach(x => {
            arr.push([...(spade ? [spade[x]] : []),...(heart ? [heart[x]] : []),...(diamond ? [diamond[x]] : []), ...(club ? [club[x]] : []) ]);
        });
        // console.log(arr);
        // howMany(arr,addIndex,"cart");
        const sendTickets = howMany(arr,addIndex,"cart");
        // console.log(sendTickets);
        axios.post(`${process.env.REACT_APP_API_URL}/profile/cartmany`,{
            ownerid: ownerid,
            gamename: gameName,
            selectedCards:sendTickets
        })
        .then((response)=>{
            // console.log(response);
            setCartContext(response.data?.cart);
            toast.success("נשלח לסל בהצלחה", {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
        .catch((error)=>{
            // setError(error.message)
            // toast.error('There was an error!', {
            toast.error(`${error.response.data}`, {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
    }
    

  return (
    <Fragment>
    {userContext ? 
    <Fragment>
        <Button onClick={()=>(printPages())} variant="outlined" size="medium" sx={buttonStyles.sumModalPopUp}>הדפס</Button>
        <Button onClick={()=>(sendToCart())} variant="outlined" size="medium" sx={buttonStyles.sumModalPopUp}>שלח לסל</Button>
    </Fragment>
    :
    <Fragment>
        <Button onClick={()=>(printPages())} variant="outlined" size="medium" sx={buttonStyles.sumModalPopUp}>הדפס</Button>
        <LoginModal text={"שלח לסל"}/>
    </Fragment>}
    {/* <Button onClick={()=>(sendToCart())} variant="outlined" size="medium" sx={{color:"black",marginLeft:"1rem",fontFamily:"CustomFont"}}>שלח לסל</Button> */}
    <Modal  
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{overflow:"scroll"}}
    >
        <Box sx={style}>        
        </Box>
    </Modal>
    </Fragment>
  );
}