import FormImage from '../assets/images/chance-form_3.png';
import { jsPDF } from "jspdf";

const numbersAxisA4 = {
  "7": 15,
  "8": 24,
  "9": 33.5,
  "10": 43,
  "J": 52,
  "Q": 61,
  "K": 70.5,
  "A": 80
}
const suitAxisA4 = {
  "Spade": 158,
  "Heart": 171,
  "Diamond": 186,
  "Club": 204
}

const moneyAxisA4 = {
  X:{
    "5": 15,
    "10": 24
  },
  Y: 107
}

const gameTypeAxisA4 = {
  X:{
  "Chance1": 15,
  "Chance2": 33.5,
  "Chance3": 52,
  "Chance4": 70,
  "ChanceAll": 89
  },
  Y: 89
}

// Axis for the form/ticket on A4 paper size.
// Ticket: 20.4 X 8.3 cm
// const numbersAxis = {
//   "7": 10.5,
//   "8": 16.9,
//   "9": 23.3,
//   "10": 29.7,
//   "J": 36.1,
//   "Q": 42.5,
//   "K": 49.1,
//   "A": 55.3
// }

// const suitAxis = {
//   "Spade": 109.3,
//   "Heart": 118.3,
//   "Diamond": 128.5,
//   "Club": 140.9
// }

// const moneyAxis = {
//   X:{
//     "5": 10.2,
//     "10": 16.8
//   },
//   Y: 74
// }

// const gameTypeAxis = {
//   X:{
//   "Chance1": 10.2,
//   "Chance2": 23.3,
//   "Chance3": 36.1,
//   "Chance4": 49.1,
//   "ChanceAll": 62
//   },
//   Y: 62
// }
const gameTypeAxis = {
  X:{
  "Chance1": 7.8,
  "Chance2": 20.2,
  "Chance3": 32.6,
  "Chance4": 45,
  "ChanceAll": 57.4
  },
  Y: 59.9
}
const numbersAxis = {
  "7": 7,
  "8": 13.5,
  "9": 20,
  "10": 26.5,
  "J": 33,
  "Q": 39.5,
  "K": 46,
  "A": 52.5
}

const suitAxis = {
  "Spade": 107.1,
  "Heart": 116.2,
  "Diamond": 126.4,
  "Club": 138.6
}

const moneyAxis = {
  X:{
    "5": 7,
    "10": 13.5
  },
  Y: 72.1
}


  export function draw(s,h,d,c,doc,money,gameType){
    // let gameType1 = typeofgame(s,h,d,c);
    // doc.addImage(FormImage, "PNG", 0, 0,0,297) // Full A4 paper 29.7cm
    // doc.addImage(FormImage, "PNG", 0, 0,83,0) // Height only 20.4cm
    // doc.addImage(FormImage, "PNG", 0, 0,0,204) // width only 8.3cm
    // doc.addImage(FormImage, "PNG", 0, 0, 82, 204) // Height + Width 20.4/8.3 cm 
    doc.text("-", moneyAxis.X[money], moneyAxis.Y);
    doc.text("-", gameTypeAxis.X[gameType], gameTypeAxis.Y);
    
    // doc.text("Like what you see? Tell the station owner to work with us!", 100 , 30, {maxWidth: 100} );
    // doc.text("Own a station? Contact us for more details.", 100 , 140, {maxWidth: 100} );
    // doc.text("בקרוב לא יהיה אפשר להדפיס בבית!", 90 , 90, {maxWidth: 100} );
    // doc.text("אוהב מה שאתה רואה? תגיד לתחנה שלך ליצר איתנו קשר!", 90 , 110, {maxWidth: 100} );
    // doc.text("בעל תחנה? יצר קשר כדי לעבוד איתנו.", 90 , 130, {maxWidth: 100} );

    if(typeof s === "string" && s!==""){
      let XAxis = numbersAxis[s];
      let YAxis = suitAxis["Spade"];
      doc.text("-", XAxis, YAxis);
    }else{
      // Draw Spade
      for (let i = 0; i < s.length; i++) {
        let XAxis = numbersAxis[s[i]];
        // let YAxis = suitAxis[suit];
        let YAxis = suitAxis["Spade"];
        doc.text("-", XAxis, YAxis);
      }
    }

    if(typeof h === "string" && h!==""){
      let XAxis = numbersAxis[h];
      let YAxis = suitAxis["Heart"];
      doc.text("-", XAxis, YAxis);
    }else{
      // Draw Heart
      for (let i = 0; i < h.length; i++) {
        let XAxis = numbersAxis[h[i]];
        let YAxis = suitAxis["Heart"];
        doc.text("-", XAxis, YAxis);
      }
    }

    if(typeof d === "string" && d!==""){
      let XAxis = numbersAxis[d];
      let YAxis = suitAxis["Diamond"];
      doc.text("-", XAxis, YAxis);
    }else{
      // Draw Diamond
      for (let i = 0; i < d.length; i++) {
        let XAxis = numbersAxis[d[i]];
        let YAxis = suitAxis["Diamond"];
        doc.text("-", XAxis, YAxis);
      }
    }

    if(typeof c === "string" && c!==""){
      let XAxis = numbersAxis[c];
      let YAxis = suitAxis["Club"];
      doc.text("-", XAxis, YAxis);
    }else{
      // Draw Club
      for (let i = 0; i < c.length; i++) {
        let XAxis = numbersAxis[c[i]];
        let YAxis = suitAxis["Club"];
        doc.text("-", XAxis, YAxis);
      }
    }
    doc.insertPage();
  }


  // check inside the inner arrays, if the length of any index is bigger than 4 -> 
  // split it and push it to the next array keeping the rest of the indexes the same.
  function chatGPT(input){

    const output = input.reduce((acc, current) => {
      const innerArrayIndex = current.findIndex((item) => Array.isArray(item));
      const innerArray = current[innerArrayIndex];
    
      if (innerArray && innerArray.length > 4) {
        const firstPart = current.slice(0, innerArrayIndex);
        const secondPart = current.slice(innerArrayIndex + 1);
    
        acc.push([...firstPart, innerArray.slice(0, 4), ...secondPart]);
        acc.push([...firstPart, innerArray.slice(4), ...secondPart]);
      } else {
        acc.push(current);
      }
      return acc;
    }, []);
    
    // console.log(output);
    return output;
  }
  
  
  export function addText(input,gameName,price) {

    price = price || "5";
    
    let arr = chatGPT(input)
  
    const doc = new jsPDF({})
    doc.setFontSize(46)

    for (let i = 0; i < arr.length; i++) {
      // draw( arr[i][0], arr[i][1], arr[i][2], arr[i][3],doc,"10","Chance4");
      draw( arr[i][0], arr[i][1], arr[i][2], arr[i][3],doc,price,gameName);
    }
    
    doc.deletePage(doc.internal.getNumberOfPages())
    doc.setDocumentProperties({
      title: "טופס להדפסה",
      // subject:"Chance ticket for printing",
      subject:`${arr.length} טפסים להדפסה`,
      author:"Wisam Hayek",
      creator:"Wisam Hayek"
    })
    // doc.output('dataurlnewwindow', `${arr.length} טפסים להדפסה.pdf`);

    // New method?
    const pdfBlob = doc.output('blob');
    // create a Blob URL for the PDF document
    const pdfBlobUrl = URL.createObjectURL(pdfBlob);
    // open the PDF document in a new browser window
    window.open(pdfBlobUrl, '_blank');
  }

  //Convert the object (true/fasle) from sendTicket to numbers in array.
  export function convertToArray(obj) {
    const outArr = [];

    for (const [key, value] of Object.entries(obj)) {
      if (value) {
        outArr.push(key.slice(1));
      }
    }
    return outArr;
  }