import * as React from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import cardStyles from '../../cardStyles';

export default function AverageModal(props) {

  const [maxDiffs4, setMaxDiffs4] = useState(props.maxDiffs4);
  const [maxDiffs3, setMaxDiffs3] = useState(props.maxDiffs3);
  const [maxDiffs2, setMaxDiffs2] = useState(props.maxDiffs2);
  const [difference, setDifference] = useState(props.difference);
  const [gameType, setGameType] = useState(props.gameType);

  useEffect(()=>{
    setMaxDiffs4(props.maxDiffs4);
    setMaxDiffs3(props.maxDiffs3);
    setMaxDiffs2(props.maxDiffs2);
    setDifference(props.difference);
    setGameType(props.gameType);
  },[props.maxDiffs4,props.maxDiffs3,props.maxDiffs2,props.difference,props.gameType])

  function test(ary) {
    var newA = [];
    for (var i = 1; i < ary.length; i++)  newA.push(ary[i] - ary[i - 1])
      return Math.max.apply(null, newA);
  }

  return (
    <Fragment>
      {gameType === 4 &&
      <Box sx={{width:{xs:"90%",md:"60%"},display:"grid",gridTemplateColumns:{xs: "1fr 1fr",md:"1fr 1fr 1fr 1fr"},margin:"auto",padding:"1rem",
      textAlign:"center",columnGap:{xs:"1rem",md:"1rem"},rowGap:"1rem"}}>
        
        <Card sx={cardStyles.averageModal}>
          <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{ fontFamily: "CustomFont" }}>מתחת 2000</Typography>
            <Typography sx={{ fontFamily: "CustomFont" }}>יצא {maxDiffs4.maxUnder2K.length} מ {difference?.length}</Typography>
            <Typography sx={{ fontFamily: "CustomFont" }}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs4.maxUnder2K.length)}</Typography>
            <Typography sx={{ fontFamily: "CustomFont", color: maxDiffs4.maxUnder2K[0] > Math.round(difference?.length / maxDiffs4.maxUnder2K.length) ? "green" : "red" }}>פעם אחרונה: {maxDiffs4.maxUnder2K[0]}</Typography>
            <Typography sx={{ fontFamily: "CustomFont" }}>מקס הפרש: {test(maxDiffs4.maxUnder2K)}</Typography>
          </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 2000-5000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs4.maxBet25K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs4.maxBet25K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs4.maxBet25K[0] > Math.round(difference?.length / maxDiffs4.maxBet25K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs4.maxBet25K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs4.maxBet25K)}</Typography>
        </Box>
        </Card>
        
        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 5000-6000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs4.maxBet56K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs4.maxBet56K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs4.maxBet56K[0] > Math.round(difference?.length / maxDiffs4.maxBet56K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs4.maxBet56K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs4.maxBet56K)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 6000-7000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs4.maxBet67K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs4.maxBet67K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs4.maxBet67K[0] > Math.round(difference?.length / maxDiffs4.maxBet67K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs4.maxBet67K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs4.maxBet67K)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 7000-8000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs4.maxBet78K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs4.maxBet78K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs4.maxBet78K[0] > Math.round(difference?.length / maxDiffs4.maxBet78K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs4.maxBet78K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs4.maxBet78K)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 8000-9000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs4.maxBet89K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs4.maxBet89K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs4.maxBet89K[0] > Math.round(difference?.length / maxDiffs4.maxBet89K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs4.maxBet89K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs4.maxBet89K)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 9000-10000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs4.maxBet910K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs4.maxBet910K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs4.maxBet910K[0] > Math.round(difference?.length / maxDiffs4.maxBet910K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs4.maxBet910K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs4.maxBet910K)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מעל 10000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs4.maxAbove10K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs4.maxAbove10K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs4.maxAbove10K[0] > Math.round(difference?.length / maxDiffs4.maxAbove10K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs4.maxAbove10K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs4.maxAbove10K)}</Typography>
        </Box>
        </Card>

      </Box>
      }

      {gameType === 3 && 
      <Box sx={{width:{xs:"90%",md:"60%"},display:"grid",gridTemplateColumns:{xs: "1fr 1fr",md:"1fr 1fr 1fr 1fr"},margin:"auto",padding:"1rem",
      textAlign:"center",columnGap:{xs:"1rem",md:"1rem"},rowGap:"1rem"}}>  
        
        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מתחת 500</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs3.maxUnder500.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs3.maxUnder500.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs3.maxUnder500[0] > Math.round(difference?.length / maxDiffs3.maxUnder500.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs3.maxUnder500[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs3.maxUnder500)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 500-1000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs3.maxBet5001K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs3.maxBet5001K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs3.maxBet5001K[0] > Math.round(difference?.length / maxDiffs3.maxBet5001K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs3.maxBet5001K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs3.maxBet5001K)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 1000-2000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs3.maxBet12K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs3.maxBet12K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs3.maxBet12K[0] > Math.round(difference?.length / maxDiffs3.maxBet12K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs3.maxBet12K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs3.maxBet12K)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מעל 2000</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs3.maxAbove2K.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs3.maxAbove2K.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs3.maxAbove2K[0] > Math.round(difference?.length / maxDiffs3.maxAbove2K.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs3.maxAbove2K[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs3.maxAbove2K)}</Typography>
        </Box>
        </Card>
      </Box>
      }

      {gameType === 2 && 
      // <Box sx={{width:{xs:"90%",md:"60%"},display:"flex",margin:"auto",padding:"1rem",flexWrap:"wrap",justifyContent:"space-evenly",
      // textAlign:"center",columnGap:{xs:"4rem",md:"6rem"},rowGap:"1rem"}}>
      <Box sx={{width:{xs:"90%",md:"60%"},display:"grid",gridTemplateColumns:{xs: "1fr 1fr",md:"1fr 1fr 1fr 1fr"},margin:"auto",padding:"1rem",
      textAlign:"center",columnGap:{xs:"1rem",md:"1rem"},rowGap:"1rem"}}>  
        
        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מתחת 50</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs2.maxUnder50.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs2.maxUnder50.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs2.maxUnder50[0] > Math.round(difference?.length / maxDiffs2.maxUnder50.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs2.maxUnder50[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs2.maxUnder50)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 50-100</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs2.maxBet50100.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs2.maxBet50100.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs2.maxBet50100[0] > Math.round(difference?.length / maxDiffs2.maxBet50100.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs2.maxBet50100[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs2.maxBet50100)}</Typography>
            </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>בין 100-200</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs2.maxBet12.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs2.maxBet12.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs2.maxBet12[0] > Math.round(difference?.length / maxDiffs2.maxBet12.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs2.maxBet12[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs2.maxBet12)}</Typography>
        </Box>
        </Card>

        <Card sx={cardStyles.averageModal}>
        <Box sx={{margin:"auto",display:"flex",flexDirection:"column",width:"max-content",alignItems:"center",justifyContent:"center"}}>
            <Typography sx={{fontFamily:"CustomFont"}}>מעל 200</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>יצא {maxDiffs2.maxAbove200.length} מ {difference?.length}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>בממוצע יוצא כל: {Math.round(difference?.length / maxDiffs2.maxAbove200.length)}</Typography>
            <Typography sx={{fontFamily:"CustomFont",color: maxDiffs2.maxAbove200[0] > Math.round(difference?.length / maxDiffs2.maxAbove200.length) ? "green" : "red"}}>פעם אחרונה: {maxDiffs2.maxAbove200[0]}</Typography>
            <Typography sx={{fontFamily:"CustomFont"}}>מקס הפרש: {test(maxDiffs2.maxAbove200)}</Typography>
        </Box>
        </Card>

      </Box>
      }
    </Fragment>
  );
}