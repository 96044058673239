// Centralized card styles for easy theme management
const cardStyles = {
    chance: {
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        maxWidth: "200px",
    },
    averageModal: {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        padding: "8px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
    },
    splitModal: {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        padding: "8px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        width: "max-content"
    },
    search: {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        padding: "8px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        width: {xs:"90%",md:"fit-content"},
        rowGap: "1rem"
    }
  };
  
export default cardStyles;