import { Box, Button, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'

export default function SystemMessages({message}) {

    const [open, setOpen] = useState();

    function handleClose(){
        setOpen(false);
    }

    useEffect(()=>{
        setOpen(true);
        // console.log(message);
    },[message])


    return (
    <Fragment>
    {open && (
    <Box sx={{bgcolor:"red",maxHeight:"3rem",display:"flex",justifyContent:"space-between"}}>
        <Button sx={{color:"black"}} onClick={()=>(handleClose())}>X</Button>
        <Box sx={{display:"flex",flexDirection:"row",gap:"1rem",marginRight:{xs:"4px",md:"2rem"}}}>
            <Typography sx={{margin:"auto"}} fontSize="small" variant='body' fontFamily="CustomFont" textAlign="right">{message.message}</Typography>
            <Typography sx={{margin:"auto"}} fontSize="small" variant='body' fontFamily="CustomFont" textAlign="right">{message.eventname}</Typography>
        </Box>
    </Box>
    )}
    </Fragment>
    )  
}