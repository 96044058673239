import './App.css';
import React, { useState } from "react";
import { Provider } from 'react-redux';
import store from './store'
import {Suspense, lazy} from "react"
import {BrowserRouter ,Route, Routes} from 'react-router-dom';
import * as ROUTES from './constants/routes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createContext } from 'react';
import NavBar from './components/navBar/navBar2';
// import NavBar from './components/navBar/navBar';
import LoadingPage from './pages/LoadingPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/home/Footer';
import './fonts.css';


import ErrorBoundary from './pages/errorBoundary';

// const Home =lazy(()=> import ('./pages/Home'));
const SearchHome =lazy(()=> import ('./pages/SearchHome'));
const Admin =lazy(()=> import ('./pages/Admin'));
const Send =lazy(()=> import ('./pages/Send'));
const Chance1 =lazy(()=> import ('./pages/Chance1'));
const Chance2 =lazy(()=> import ('./pages/Chance2'));
const Chance3 =lazy(()=> import ('./pages/Chance3'));
const Chance4 =lazy(()=> import ('./pages/Chance4'));
const Chance2Dif =lazy(()=> import ('./pages/Chance2Dif'));
const Chance3Dif =lazy(()=> import ('./pages/Chance3Dif'));
const Chance4Dif =lazy(()=> import ('./pages/Chance4Dif'));
const Splits =lazy(()=> import ('./pages/Splits'));
const Cart =lazy(()=> import ('./pages/Cart'));
const Groups =lazy(()=> import ('./pages/Groups'));
const GroupsID =lazy(()=> import ('./pages/GroupsID'));
const NotFound =lazy(()=> import ('./pages/not-found.js'));
const StationHome =lazy(()=> import ('./pages/stationHome.js'));
const Profile =lazy(()=> import ('./pages/Profile'));
const ResetPassword =lazy(()=> import ('./pages/ResetPassword.js'));
const Test777 =lazy(()=> import ('./pages/test777.js'));
const TestLotto =lazy(()=> import ('./pages/testLotto.js'));
const Lotterychecker =lazy(()=> import ('./pages/Lotterychecker.jsx'));
const LottoGenerator =lazy(()=> import ('./pages/LottoGenerator.jsx'));
// const Image =lazy(()=> import ('./pages/image3pdf.js'));
// const Browse =lazy(()=> import ('./pages/Browse'));
// const Product =lazy(()=> import ('./pages/Product'));
// const Checkout =lazy(()=> import ('./pages/Checkout.js'));

export const UserContext = createContext();
export const ProfileContext = createContext();
export const ResultContext = createContext();
export const CartContext = createContext();

function App() {

  const [userContext, setUserContext] = useState([]);
  const [profileContext, setProfileContext] = useState([]);
  const [resultContext, setResultContext] = useState([]);
  const [cartContext, setCartContext] = useState([]);

  const CLIENTID="839890200657-ab7pl0ud5o5a1bpvi69vjjiud8jrrlqt.apps.googleusercontent.com";

  return (
    <Provider store={store}>
    <ErrorBoundary>
    <GoogleOAuthProvider clientId={CLIENTID}>
    <UserContext.Provider value={{userContext, setUserContext}}>
    <ProfileContext.Provider value={{profileContext, setProfileContext}}>
    <ResultContext.Provider value={{resultContext, setResultContext}}>
    <CartContext.Provider value={{cartContext, setCartContext}}>
    <BrowserRouter>
    <NavBar />
    <div style={{minHeight:"80vh"}}>
    <ToastContainer 
    position="top-center" autoClose={2000} limit={3} hideProgressBar={false} newestOnTop closeOnClick rtl={false} 
    pauseOnFocusLoss={false} draggable pauseOnHover theme="light"/>
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route exact path={ROUTES.HOME} element={<SearchHome/>}/>
        <Route exact path={ROUTES.SEND} element={<Send/>}/>
        <Route exact path={ROUTES.CHANCE1} element={<Chance1/>}/>
        <Route exact path={ROUTES.CHANCE2} element={<Chance2/>}/>
        <Route exact path={ROUTES.CHANCE3} element={<Chance3/>}/>
        <Route exact path={ROUTES.CHANCE4} element={<Chance4/>}/>
        <Route exact path={ROUTES.CHANCE2DIF} element={<Chance2Dif/>}/>
        <Route exact path={ROUTES.CHANCE3DIF} element={<Chance3Dif/>}/>
        <Route exact path={ROUTES.CHANCE4DIF} element={<Chance4Dif/>}/>
        <Route exact path={ROUTES.SPLITS} element={<Splits/>}/>
        <Route exact path={ROUTES.ADMIN} element={<Admin/>}/>
        <Route exact path={ROUTES.CART} element={<Cart/>}/>
        <Route exact path={ROUTES.GROUPS} element={<Groups/>}/>
        <Route exact path={ROUTES.GROUPSID} element={<GroupsID/>}/>
        <Route exact path={ROUTES.STATIONHOME} element={<StationHome/>}/>
        <Route exact path={ROUTES.PROFILE} element={<Profile/>}/>
        <Route path={ROUTES.RESETPASSWORD} element={<ResetPassword/>}/>
        <Route exact path={ROUTES.TEST777} element={<Test777/>}/>
        <Route exact path={ROUTES.TESTLOTTO} element={<TestLotto/>}/>
        <Route exact path={ROUTES.LotteryChecker} element={<Lotterychecker/>}/>
        <Route exact path={ROUTES.LottoGenerator} element={<LottoGenerator/>}/>
        <Route path={ROUTES.NOT_FOUND} element={<NotFound/>}/>
        {/* <Route exact path={ROUTES.IMAGE} element={<Image/>}/> */}
        {/* <Route exact path={ROUTES.NULL} element={<SearchHome/>}/> */}
        {/* <Route exact path={ROUTES.BROWSE} element={<Browse/>}/>
        <Route exact path={ROUTES.BROWSECAT} element={<Browse/>}/>
        <Route exact path={ROUTES.CART} element={<Cart/>}/>
        <Route exact path={ROUTES.PRODUCT} element={<Product/>}/>
        <Route path={ROUTES.CHECKOUT} element={<Checkout/>}/> */}
      </Routes>
    </Suspense>
    </div>
    <Footer />
  </BrowserRouter>
  </CartContext.Provider>
  </ResultContext.Provider>
  </ProfileContext.Provider>
  </UserContext.Provider>
  </GoogleOAuthProvider>
  </ErrorBoundary>
  </Provider>
  );
}

export default App;