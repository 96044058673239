import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import axios from '../../constants/axiosInstance'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '85%', md: '50%' },
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: '1rem',
  borderRadius: '1rem',
  margin:"auto"
};

const ResetPasswordDialog = ({ handleOpen, open, setResetPasswordOpen }) => {
    const [resetError,setResetError] = useState(null)
    const [resetEmail, setResetEmail] = useState('');
    const [success, setSuccess] = useState(false);

    function handleResetPassword() {
        // console.log("here");
        axios.post(`${process.env.REACT_APP_API_URL}/password/forgotpassword`,{
          email:resetEmail
        })
        .then(response => {
          // Handle successful reset password request
          // console.log(response.data);
          // Close the reset password dialog
          //   setResetPasswordOpen(false);
          // Open a success message or redirect the user to a success page
          setSuccess(true);
          // ...
        })
        .catch(error => {
          console.log(error.response.data);
          setResetError(error.response.data);
        });
      }

    const handleClose = () => {
        setResetPasswordOpen(false);
        setResetEmail('');
        setResetError('');
        setSuccess(false);
    }

  return (
    <Dialog open={open} onClose={() =>handleClose()} PaperProps={{sx: style}}>
        {!success ?
        <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '20rem', margin: 'auto', gap: '1rem' }}>
            <Button sx={{fontFamily:"CustomFont"}} onClick={()=>{
                handleOpen();
                handleClose();
            }}>חזרה</Button>
            <Typography sx={{fontFamily:"CustomFont"}} variant="h4">הקלד דואר אלקטרוני</Typography>
            <TextField
                id="email-input"
                label="דואר אלקטרוני"
                type="email"
                autoComplete="email"
                value={resetEmail}
                onChange={(event) => setResetEmail(event.target.value)}
            />
            <Button disabled={!resetEmail} sx={{fontFamily:"CustomFont"}} variant='contained' onClick={()=>(handleResetPassword())}>איפוס סיסמה</Button>
            {resetError && <Typography color={"red"} textAlign={"center"}>{resetError.message || resetError}</Typography>}
        </Box>
        :
        <Box sx={{minHeight:"5rem", display: "flex", alignItems: "center", justifyContent: "center", textAlign:"center",margin:"auto"}}>
            {/* <Typography>Password reset link was sent to the email address</Typography> */}
            <Typography sx={{fontFamily:"CustomFont"}}>אימייל לאיפוס סיסמה נשלח בהצלחה לדוא האלקטרוני</Typography>
        </Box>      
        }
    </Dialog>
  );
};

export default ResetPasswordDialog;
