import axios from 'axios';

const sendErrorToServer = (error) => {

    let user = null;
    const storedUser = localStorage.getItem('activeUser');
    if (storedUser) {
        const { email, id, name } = JSON.parse(storedUser);
        user = { email, id, name };
    }

    let errorResponse = error.response;
    const errorData = {
        error,
        user,
        errorResponse
    };
    // console.log(errorData);

    // Send the error to your server endpoint using axios
    axios.post(`${process.env.REACT_APP_API_URL}/error-logs`, errorData)
    .then(response => {
        // Log success or perform any additional actions
        console.log('Error sent to server');
    })
    .catch(error => {
        // Log the error if there was an issue sending the error to the server
        console.error('Failed to send error to server:', error);
    });
};

export default sendErrorToServer;
