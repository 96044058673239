import * as React from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { useEffect } from 'react';

import PrintPages from './printPages.jsx';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import buttonStyles from '../../buttonStyles.js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: "100%", md: "50%"},
  // height: {xs: "auto", md: "auto"},
  // height: "100vh",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  padding: {xs:0,md: "1rem"},
  borderRadius: "1rem",
  // marginTop: {xs: "auto", md: "auto"},
  // marginTop:"auto",
};

export default function SumModal(props) {

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
      setOpen(true);
  }

  const handleClose = () => {
      setOpen(false);
  }

  // Stikcy Header Table / Pagination MUI Start
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // Stikcy Header Table / Pagination MUI Ends

  const [summary, setSummary] = useState(props.sum);
  const [spade, setSpade] = useState(props.spade);
  const [heart, setHeart] = useState(props.heart);
  const [diamond, setDiamond] = useState(props.diamond);
  const [club, setClub] = useState(props.club);
  const [lastTime, setLastTime] = useState(props.lastTime);

  useEffect(()=>{
    // console.log(props.sum);
    // console.log(props.spade);
    // console.log(props.heart);
    // console.log(props.diamond);
    // console.log(props.club);
    setSummary(props.sum);
    setSpade(props.spade);
    setHeart(props.heart);
    setDiamond(props.diamond);
    setClub(props.club);
    setLastTime(props.lastTime)
  },[props.sum,props.spade,props.heart,props.diamond,props.club])

  
  return (
    <Fragment>
    <Button sx={buttonStyles.sumModal} disabled={summary.length===0} variant='contained' onClick={handleOpen}>{summary?.length}</Button>
    <Modal  
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{overflow:"scroll"}}
    >
        <Box sx={style}>
          <Box sx={{display:"flex",flexDirection:"row",justifyContent:"left",columnGap:"1rem",alignItems:"center",padding:"4px"}}>
          <Button variant="outlined" size='small' sx={buttonStyles.sumModalClose} onClick={handleClose}>X</Button>
          <PrintPages sum={summary} spade={spade} heart={heart} diamond={diamond} club={club}/>
          </Box>
        {/* <PrintPages {...props}/> */}
        {/* <PrintPages props={props}/> */}
        <Paper sx={{ maxWidth:1000, width: { xs: '95%', md: 'auto' }, margin:"auto",marginBottom:"2rem",marginTop:"0rem" }}>
        <TableContainer component={Paper} sx={{height:"500px"}}>
            <Table stickyHeader sx={{ margin: "auto",maxHeight:"max-content" }} aria-label="Result Table">
                <TableHead>
                    <TableRow>
                        {spade?.length>0 && <TableCell align="center">עלה</TableCell>}
                        {heart?.length>0 && <TableCell align="center">לב</TableCell>}
                        {diamond?.length>0 && <TableCell align="center">יהלום</TableCell>}
                        {club?.length>0 && <TableCell align="center">תלתן</TableCell>}
                        {lastTime?.length>0 && <TableCell align="center">פעם אחרונה</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                {summary
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row,index) => (
                    <TableRow key={index}>
                    {spade?.length>0 && <TableCell align="center">{spade[row]}</TableCell>}
                    {heart?.length>0 && <TableCell align="center">{heart[row]}</TableCell>}
                    {diamond?.length>0 && <TableCell align="center">{diamond[row]}</TableCell>}
                    {club?.length>0 && <TableCell align="center">{club[row]}</TableCell>}
                    {lastTime?.length>0 && <TableCell align="center">{lastTime[row]}</TableCell>}
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            {(lastTime?.length>10 || page>0) &&
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={summary?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="שורות בעמוד:"
            />
            }
        </Paper>
        </Box>
    </Modal>
    </Fragment>
  );
}