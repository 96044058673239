import axios from 'axios';
import sendErrorToServer from './errorHandler';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // Log or send the error to your server
    sendErrorToServer(error);

    // Pass the error to the calling code
    return Promise.reject(error);
  }
);

export default axiosInstance;
