import { configureStore } from '@reduxjs/toolkit'
import resultHistory from './slices/resultHistory'
import resultSlice from './slices/resultSlice'


export default configureStore({
  reducer: {
    results: resultSlice,
    history: resultHistory
  }
})